.customComponents__progressbar {
    width: 64px;
    height: 64px;

    &__path {
        stroke: #00A3FF;
        stroke-linecap: round;
        transform-origin: center;
        transition: stroke 0.3s ease;
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        transform: rotate(-90deg);
        transition: stroke-dashoffset 0.3s ease;
    }
}