.component__kanbanStatistics{
    background-color: #2b2c3e;
    margin: auto;
    width: auto;
    height: auto;
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
    padding: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &__close{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 30px;
        border-radius: 100%;
        background-color: rgb(133, 8, 8);
        padding: 5px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__lead{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1px;
        background-color: gray;
        padding: 1px;
        &>div{
            background-color: #2b2c3e;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &>p:first-child{
                font-size: 24px;
            }
            &>p:last-child{
                font-size: 30px;
                margin-top: 10px;
            }
        }
    }
    &__deal{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1px;
        background-color: gray;
        padding: 1px;
        &>div{
            background-color: #2b2c3e;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &>p:first-child{
                font-size: 24px;
            }
            &>p:last-child{
                font-size: 30px;
                margin-top: 10px;
            }
        }
    }
}