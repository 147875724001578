.route__kreirajNaljepniceHR__first{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:20px;
    gap: 20px;
    &>p{
        font-family: Roboto;
    }
    &>textarea{
        width: 100%;
        height: 400px;
        outline: none;
        padding: 20px;
    }
    &>button{
        padding: 5px 15px;
        cursor: pointer;
    }
}




@media print {
    #root {
        padding: 0 !important;
    }
    .route__kreirajNaljepniceHR {
        padding: 0 !important;
    }
}
.route__kreirajNaljepniceHR {
    padding: 5px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100vh;
    grid-auto-rows: 100vh;
    width: 100%;
    height: auto;
    font-size: 10px;

    // @media print {
    //     width: calc(100vw);
    //     height: calc(100vh);
    // }
    &__wrap {
        display: grid;
        grid-template-rows: repeat(2, calc(50%));
        grid-template-columns: repeat(2, 50%);
        justify-content: flex-start;
        align-items: flex-start;
        page-break-after: always;
        border-bottom: 1px solid black;
        margin-bottom: 20px;
        margin-top: 20px;
        gap: 20px;

        @media print {
            border: none;
            margin-bottom: 0;
        }
    }
    // &__wrapX {
    //     display: grid;
    //     grid-template-rows: repeat(6, calc(100% / 6));
    //     grid-template-columns: repeat(15, 6,6666666667%)  !important;
    //     justify-content: flex-start;
    //     align-items: flex-start;
    //     page-break-after: always;
    //     border-bottom: 1px solid black;
    //     margin-bottom: 20px;
    //     margin-top: 20px;

    //     @media print {
    //         border: none;
    //         margin-bottom: 0;
    //     }
    // }

   &__naljepnica{
    width: 100%;
    height: 100%;
    
    &__left__barcodeBottom{
        font-size: 20px;
        font-weight: 900;
    }
    &__left__dexpress{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;

    }
    &__right__sender{
        margin-top: 10px;
    }
   }
}
