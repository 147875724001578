.component__chat__wrap__sidebar {
    width: 350px;
    height: 100%;
    background-color: #272737;
    position: relative;

    display: grid;
    grid-template-rows: 88px 110px 1fr;
    grid-template-columns: 1fr;
    overflow: hidden;
    transition: width 0.3s ease;

    
    @media screen and (max-width: 890px){
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
    }
    &__logo {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 30px;

        &__hamburger {
            width: 32px;
            height: 32px;
            margin-right: 20px;
        }
        & > img:first-child {
            width: 146px;
            height: 46px;
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 60px 50px 1fr;
        grid-template-columns: 1fr;

        &__addBtn {
            width: 100%;
            height: 100%;
            background-color: #6664E5;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            & > span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
            }
            & > img {
                width: 12px;
                height: 12px;
                transform-origin: center;
                transform: rotate(45deg);
                margin-right: 14px;
            }
        }
        &__search {
            width: 100%;
            height: 100%;
            background-color: transparent;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 20px 1fr;
            column-gap: 12px;
            padding: 0px 20px;
            place-items: center;
            border-bottom: 1px solid #525274;

            & > img {
                width: 20px;
                height: 20px;
            }
            & > input {
                width: 100%;
                height: 100%;
                background-color: transparent;
                outline: none;
                border: none;
                color: white;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                &::placeholder {
                    color: rgb(255, 255, 255);
                }
            }
        }
    }
    &__chats {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        &__chat {
            width: 100%;
            min-width: 350px;
            height: 70px;
            display: grid;
            grid-template-rows: 50% 50%;
            grid-template-columns: 36px 1fr 24px;
            column-gap: 20px;
            row-gap: 6px;
            border-bottom: 1px solid #525274;
            padding: 14px 20px;
            place-items: center;
            cursor: pointer;
            
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #2e2e46;
            }
            &--active {
                background-color: #454569 !important;
            }
            & > p {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            &__user {
                position: relative;
                width: 36px;
                height: 36px;

                & > img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                & > span {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    border: 1px solid #272737;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -1px;
                    right: -1px;
                    transition: background-color 0.3s ease;
                }
            }
            &__chatName {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
            }
            &__lastMessage {
                width: 100%;
                height: 12px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #929292;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &__notification {
                width: 22px;
                height: 22px;
                position: relative;
                box-shadow: inset 0 0 5px grey;

                padding: 12px;
                background-color: #F50B0B;
                color: white;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                border-radius: 50%;
                width: 16px;
                height: 16px;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    &__createChat {
        position: absolute;
        top: 160px;
        left: 0%;
        width: 100%;
        height: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #272737;
        z-index: 2;
        padding: 0 20px;
        transition: height 0.5s ease, padding 0.5s ease;

        &--active {
            padding: 32px 20px;
            height: calc(100% - 160px);
        }
        & > h3 {
            margin-bottom: 50px;
        }
        & > p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
        }
        &__users {
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            &__user {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                background-color: #525274;
                padding: 11px 24px;
                border-radius: 2px;

                & > img {
                    margin-right: 10px;
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;

                    &:first-child {
                        cursor: pointer;
                    }
                }
            }
        }
        &__buttons {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 54px;

            &__button {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                background-color: #3D3D55;
                border-radius: 53px;
                padding: 12px 50px;
                cursor: pointer;
                transition: filter 0.3s ease;

                &:first-child {
                    background-color: #6664E5;
                }
                &:hover {
                    filter: brightness(80%);
                }
            }
        }
    }
}