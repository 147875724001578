.component__editLead {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: auto;
    overflow-x: hidden;
    background-color: #2b2c3e;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 30px;
    cursor: auto;
    .component__agentCall__info__tabsContent__single__dropdownContainer{
        width: 100%;
        
    }
    .component__customComponent__dropbox {
        width: 100%;
        margin: auto;
        margin-top: 20px ;
        margin-bottom: 20px ;
        width: 100%;
    }
    &__statusContainer{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 30px;
        flex-wrap: wrap;
        &__single{
            width: 185px;
            margin: 0 20px;
            padding: 10px 20px;
            background-color: #323341;
            border-radius: 40px;
            cursor: pointer;
            transition:all 0.3s ease;
        }
    }
}
