.component__breakModal{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.726);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease;
    z-index: 1000;
    img{
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    p{
        font-family: Roboto;
        font-size: 35px;
        color: white;
        margin-top: 15px;
    }
    button{
        width: 300px;
        height: 50px;
        background-color: rgb(129, 76, 179);
        color: white;
        font-size: 30px;
        font-family: Roboto;
        border: none;
        margin-top: 20px;
        cursor: pointer;
        border-radius: 5px;
    }
    &--hidden{
        top: -100%;
    }
}
.component__breakModal-hidden{
    top: -200vh;
    opacity: 0;
}