@keyframes btn-anim1 {
    0% {
        left: -100%;
    }
    50%,
    100% {
        left: 100%;
    }
}
@keyframes btn-anim2 {
    0% {
        top: -100%;
    }
    50%,
    100% {
        top: 100%;
    }
}
@keyframes btn-anim3 {
    0% {
        right: -100%;
    }
    50%,
    100% {
        right: 100%;
    }
}
@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }
    50%,
    100% {
        bottom: 100%;
    }
}

.route__crm {
    width: 100%;
    height: max-content;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0 50px;
    background-color: #0f132685;
    display: grid;
    grid-template-rows: max-content max-content;
    grid-auto-rows: max-content;
    grid-template-columns: 1fr;

    &__kpiContainer {
        // display: flex;
        // justify-content: flex-start;
        // align-items: flex-start;
        // flex-wrap: wrap;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        &__kpiSingle {
            background-color: #2e2e41;
            height: 100px;
            width: 100%;
            margin-left: 10px;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 17px 30px 15px 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.35);
            border-radius: 5px;
            @media screen and (max-width: 1700px) {
                margin-top: 10px;
            }
            @media screen and (max-width: 800px) {
                width: 100%;
            }
            img {
                margin-right: 35px;
            }
            > div {
                width: 100%;

                > p {
                    font-family: Roboto;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #999fae;
                }
                > h4 {
                    font-family: Roboto;
                    font-size: 33px;
                    font-weight: 700;
                    line-height: 39px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: white;

                    & > span {
                        font-size: 12px;
                        margin-left: auto;
                        padding: 0px 10px;
                        color: #6664e5;
                        cursor: pointer;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: #d5d0d0;
                        }
                    }
                }
            }
        }
        & > .route__crm__kpiContainer__single2 {
            background: linear-gradient(0deg, rgba(49, 50, 73, 0.63), rgba(49, 50, 73, 0.562)),
                linear-gradient(0deg, #68729a91, #68729a9c);
        }
        &__single2 {
            margin-top: 20px;
            min-height: 171px;
            width: 100%;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 17px 50px 15px 30px;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: auto 1fr;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            margin-left: 10px;
            overflow: hidden;
            border: 2px solid #68729a;
            position: relative;
            transition: all 0.3s ease;
            & > span {
                position: absolute;
                display: block;
            }
            &:hover {
                
                & > span:nth-child(1) {
                    top: 0;
                    left: -100%;
                    width: 100%;
                    height: 4px;
                    background: linear-gradient(90deg, transparent, #9D4FA7);
                    animation: btn-anim1 1s linear infinite;
                }
                & > span:nth-child(2) {
                    top: -100%;
                    right: 0;
                    width: 4px;
                    height: 100%;
                    background: linear-gradient(180deg, transparent, #9D4FA7);
                    animation: btn-anim2 1s linear infinite;
                    animation-delay: 0.25s;
                }
                & > span:nth-child(3) {
                    bottom: 0;
                    right: -100%;
                    width: 100%;
                    height: 4px;
                    background: linear-gradient(270deg, transparent, #9D4FA7);
                    animation: btn-anim3 1s linear infinite;
                    animation-delay: 0.5s;
                }
                & > span:nth-child(4) {
                    bottom: -100%;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background: linear-gradient(360deg, transparent, #9D4FA7);
                    animation: btn-anim4 1s linear infinite;
                    animation-delay: 0.75s;
                }
            }
            @media screen and (max-width: 1700px) {
                margin-top: 20px;
            }
            @media screen and (max-width: 800px) {
                width: 100%;
            }

            img {
                margin-right: 25px;
            }
            > div {
                > h1 {
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 30px;
                    color: white;
                    line-height: 42px;
                    text-align: center;
                    max-width: 100%;
                    text-align: center;
                }
                > p {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 30px;
                    color: white;
                    text-align: center;
                    max-width: 100%;
                }
            }
            &.cloned-element {
                display: flex;
            }
        }
    }
    &__chatBuble {
        position: absolute;
        right: 50px;
        bottom: 50px;
        width: 50px;
        height: 50px;
        background-color: blue;
        border-radius: 50%;
        cursor: pointer;
    }
}

// .component__chat {
//     cursor: auto;
//     background-color: #34333c81;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     scrollbar-color: white transparent;
//     scrollbar-width: thin !important;
//     /* width */
//     ::-webkit-scrollbar {
//         width: 5px;
//     }

//     /* Track */
//     ::-webkit-scrollbar-track {
//         box-shadow: inset 0 0 5px grey;
//         border-radius: 10px;
//     }

//     /* Handle */
//     ::-webkit-scrollbar-thumb {
//         background: white;
//         border-radius: 10px;
//     }
//     &__list {
//         width: 400px;
//         background-color: #272737;
//         height: 100%;
//         overflow-y: scroll;
//         &__single {
//             display: flex;
//             align-items: center;
//             justify-content: flex-start;
//             padding: 20px;
//             border-bottom: 1px solid gray;
//             transition: all 0.3s ease;
//             cursor: pointer;
//             position: relative;
//             img {
//                 border-radius: 50%;
//                 width: 50px;
//                 margin-right: 10px;
//             }
//             div {
//                 >p {
//                     font-family: Roboto;
//                     font-size: 18px;
//                     color: white;
//                     >small {
//                         font-size: 14px;
//                         color: #929292;
//                         b {
//                             background-color: green;
//                             border-radius: 50%;
//                             color: green;
//                             margin-right: 5px;
//                             padding: 0 4px;
//                             color: #929292;
//                         }
//                     }
//                 }
//             }
//             &__roles {
//                 display: flex;
//                 justify-content: flex-start;
//                 align-items: center;
//                 flex-wrap: wrap;
//                 display: none;
//                 >p {
//                     font-size: 14px !important;
//                     color: #11002f !important;
//                     background-color: white;
//                     padding: 0 4px;
//                     border-radius: 3px;
//                     line-height: 18px !important;
//                     font-family: Roboto;
//                     margin-right: 3px;
//                     margin-bottom: 5px;
//                 }
//             }
//             &--active {
//                 background-color: #1D1D29;
//                 transition: all 0.3s ease;
//             }
//             &__notify {
//                 display: inline-flex;
//                 justify-content: center;
//                 align-items: center;
//                 padding: 5px 10px;
//                 margin-right: 10px;
//                 border-radius: 10px;
//                 background-color: #6664e5;
//             }
//         }
//         &__head {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             padding: 20px;
//             border-bottom: 1px solid gray;
//             height: 100px;
//         }
//         &__create {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 100%;
//             height: 50px;
//             &__button {
//                 background-color: transparent;
//                 border: none;
//                 padding: 5px 10px;
//                 color: white;
//                 font-family: Roboto;
//                 font-weight: 700;
//                 cursor: pointer;
//                 width: 100%;
//                 height: 100%;
//                 background-color: #6664e5;
//             }
//         }
//     }
//     &__singleChat {
//         width: calc(100% - 400px);
//         background-color: #504e5a;
//         height: 100%;
//         border-left: 1px solid gray;
//         padding-bottom: 100px;
//         position: relative;
//         &__content {
//             width: 100%;
//             height: calc(100% - 100px);
//             overflow: hidden scroll;
//             padding: 30px;
//             background-color: #3d3d55;
//             background-size: cover;
//             &__spinner {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 height: calc(100% - 100px);
//                 width: 100%;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 background-color: #0b05149d;
//                 z-index: 1000;
//             }
//         }
//         &__header {
//             width: 100%;
//             height: 100px;
//             background-color: #272737;
//             border-bottom: 1px solid gray;
//             display: flex;
//             justify-content: flex-start;
//             align-items: center;
//             box-sizing: border-box;
//             padding: 0 50px;
//             img {
//                 width: 70px;
//                 height: auto;
//                 min-height: 20px;
//                 border-radius: 50%;
//                 margin-right: 15px;
//             }
//             p {
//                 font-family: Roboto;
//                 color: white;
//                 small {
//                     font-size: 14px;
//                     b {
//                         background-color: green;
//                         border-radius: 50%;
//                         color: green;
//                         margin-right: 5px;
//                         padding: 0 4px;
//                     }
//                 }
//             }
//             &__inputChange{
//                 background-color: transparent;
//                 color: white;
//                 border: 1px solid gray;
//                 padding: 5px 10px;
//                 border-radius: 5px;
//             }
//             &__buttonChange{
//                 color: white;
//                 padding: 5px 10px;
//                 border: none;
//                 border-radius: 5px;
//                 cursor: pointer;
//                 margin-left: 10px;
//                 font-size: 16px;
//             }
//         }
//         &__newMsg {
//             position: absolute;
//             bottom: 0;
//             width: 100%;
//             height: 100px;
//             box-sizing: border-box;
//             padding: 10px;
//             border-top: 1px solid #525274;
//             background-color: #272737;
//             &__first {
//                 display: flex;
//                 width: 100%;
//                 height: 50%;
//                 justify-content: flex-start;
//                 cursor: auto;
//                 >input {
//                     width: calc(100% - 130px);
//                     border-radius: 5px;
//                     border: 1px solid #525274;
//                     background-color: transparent;
//                     color: white;
//                     padding: 0 10px;
//                     outline: none;
//                 }
//                 >button {
//                     border-radius: 10px;
//                     width: 100px;
//                     border: none;
//                     outline: none;
//                     font-family: Roboto;
//                     font-size: 20px;
//                     font-weight: 700;
//                     color: white;
//                     background-color: transparent;
//                     cursor: pointer;
//                     background-color: #6664e5;
//                     margin-left: 30px;
//                 }
//                 &__notAllowed{
//                     color: white;
//                     width: 100%;
//                     text-align: center;
//                     font-family: Roboto;
//                 }
//             }
//             &__second {
//                 width: 100%;
//                 height: 50%;
//                 display: flex;
//                 align-items: center;
//                 justify-content: start;
//                 margin-top: 5px;

//                 label {
//                     margin-right: 20px;

//                     img {
//                         height: 20px;
//                         cursor: pointer;
//                     }
//                 }
//             }
//         }
//     }
//     &__close {
//         cursor: pointer;
//         position: absolute;
//         top: 0px;
//         color: white;
//         border: none;
//         background-color: rgb(124, 27, 27);
//         right: 0px;
//         text-transform: capitalize;
//         font-size: 20px;
//         padding: 5px;
//         width: 60px;
//         border-bottom-left-radius: 15px;
//         transition: all 0.3s ease;
//         z-index: 100;
//         &:hover {
//             background-color: rgb(216, 38, 38);
//         }
//     }
// }
.component__pause {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    cursor: auto;
    p {
        font-family: Roboto !important;
        font-size: 25px !important;
        font-weight: 500 !important;
        line-height: 30px !important;
    }
    button {
        width: 350px;
        height: 50px;
        border: none;
        border-radius: 5px;
        color: white;
        font-family: Roboto;
        font-size: 33px;
        cursor: pointer;
        margin-top: 10px;
    }
    &__buttonGreen {
        background-color: green;
    }
    &__buttonRed {
        background-color: red;
    }
}
.component__odjava {
    width: 400px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #1d1d29;
    cursor: auto;
    margin-left: calc(50% - 200px);

    &__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #2b2c3e;
        padding: 40px;
        box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.75);

        & > p {
            font-family: Roboto;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: white;
            max-width: 100%;
            text-align: left;
            width: calc(100% - 110px);
        }
    }
    p {
        width: 100%;
        text-align: center;
        height: 50px;
        font-family: Roboto;
    }
    button {
        width: 350px;
        height: 50px;
        font-size: 25px;
        margin-top: 20px;
        color: white;
        border-radius: 5px;
        border: none;
        font-family: Roboto;
        cursor: pointer;
    }
}
.component__storn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: auto;
    &>p {
        width: 100%;
        text-align: center;
        height: 50px;
        font-family: Roboto;
    }
    button {
        width: 350px;
        height: 50px;
        font-size: 25px;
        margin-top: 20px;
        color: white;
        border-radius: 5px;
        border: none;
        font-family: Roboto;
        cursor: pointer;
    }
}
.component__agentCall,
.component__chat,
.component__pause,
.component__odjava {
    opacity: 0;
    transition: opacity 0.3s ease;
}
