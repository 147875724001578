.component__sipButton {
    width: 50px;
    height: 50px;
    background-color: #2e2e41;
    border: 1px solid gray;
    border-radius: 50px;
    padding: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.408);

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > img {
        width: 50px;
        height: 50px;
        margin: 0;
        padding: 16px;
    }

    &__sipCallModal {
        opacity: 0;
        position: absolute;
        top: -50%;
        left: 100%;
        width: 284px;
        height: 414px;
        padding: 20px;
        z-index: 9999999;
        overflow: hidden;

        background: #272737;
        box-shadow: 0px 0px 8px #04030E;
        border-radius: 5px;

        display: grid;
        grid-template-columns: 30px repeat(3, 1fr);
        grid-template-rows: 54px repeat(4, 1fr) 36px;
        row-gap: 12px;
        column-gap: 12px;

        &__buttons {
            grid-row: 1 / span all;
            grid-column: 1;
            display: flex;
            align-items: center;
            flex-direction: column;

            & > div {
                width: 100%;
                height: 30px;
                background-size: calc(100% + 5px) calc(100% + 10px);
            }
            &__onlineUsersWrap {
                position: absolute;
                top: 0;
                left: 60px;
                height: 100%;
                width: 0;
                z-index: 9;
                overflow: hidden;
                background-color: #1e1e2b;
            }
            &__phoneBook, &__recentCalls {
                background-color: white;
                border-radius: 50%;
                background-size: 100% 100% !important;

                &.cloned-element {
                    z-index: 99999999 !important;
                    background-image: none !important;
                }
            }
            &__onlineUsers {
                margin-bottom: 10px;

                &__wrap {
                    color: white;
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;

                    &__inner {
                        width: 100%;
                        min-width: 220px;
                        max-width: 100%;
                        height: auto;
                        min-height: 100%;
                        display: flex;
                        flex-direction: column;

                        &__usr {
                            padding: 10px 10px;
                            display: grid;
                            grid-template-rows: auto;
                            grid-template-columns: 32px 1fr;
                            column-gap: 10px;
                            cursor: pointer;
                            transition: background-color 0.3s ease;

                            & > img {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                            }
                            & > span {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: wrap;
                            }
                            &:hover {
                                background-color: #272737;
                            }
                        }
                    }
                }
            }
        }
        &__callNumber {
            grid-column: 2 / span 3;
            background-color: #525274;
            border-radius: 5px;
            position: relative;

            & > input {
                position: absolute;
                top: 0;
                left: 0;
                background-color: transparent;
                color: white;
                border: none;
                outline: none;
                padding: 15px 20px;
                padding-right: 40px;
                width: 100%;
                height: 100%;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
            }
            & > img {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                opacity: 0;
                transition: opacity 0.3s ease;
                cursor: pointer;
                padding: 5px;
            }
        }
        &__dialNum {
            width: 100%;
            height: 100%;
            color: white;
            background: #3D3D55;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
        }
        &__finalDialButtons {
            grid-column: 2 / span 3;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;
            column-gap: 25px;

            &__call, &__close {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            &__call {
                background: #6664E5;
                border-radius: 53px;
                padding: 0px 33px;
            }
        }
    }
    &__contactBook {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        cursor: default;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
        padding: 10px;

        & .customComponents__table {
            display: block;
            width: 100%;
        }

        &__wrap, &__wrap__add {
            background-color: rgb(39, 39, 55);
            padding: 10px 10px;
            width: 600px;
            margin: 0 auto;
            cursor: default;
            position: relative;

            &__buttons {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 20px;

                & > * {
                    margin-left: 10px;
                }
            }
            &__add {
                position: relative;

                & > * {
                    margin: 15px 0px;
                }
                & > div > button {
                    margin: 0px 10px;
                }
            }
        }
    }
    &__recentCalls {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: white;
        overflow-y: auto;

        &__wrap {
            width: 900px;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #272737;
            padding: 10px;

            &__buttons {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                & > p {
                    font-weight: bold;
                    font-family: "Roboto";
                }
            }
            & .customComponents__table {
                &__data {
                    > p {
                        text-align: center;
                    }
                }
            }
        }
    }
}
.component__sipButton__contactBook__wrap__buttons__add.cloned-element {
    z-index: 999999999 !important;
}