.route__shippmentAgent__dealsList {
    width: 100%;
    height: auto;
    min-height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    cursor: default;
    overflow-y: auto;
    padding: 10px;

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        & > * {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }
}
.route__processDeals__dealsList{
    padding: 20px;
    &>p{
        font-size: 18px;
        margin-bottom: 20px;
    }
    &__buttons{
        margin-top: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        &>button{
            padding: 10px 20px;
            border-radius: 3px;
            cursor: pointer;
            border: 1px solid red;
            background-color: transparent;
            font-family: Roboto;
            color: white;
        }
    }
}