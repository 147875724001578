.component__backendTasks {
    cursor: default;
    width: 100%;
    height: max-content;

    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    flex-wrap: wrap;

    transition: width 0.3s ease;

    &__line {
        width: 100%;
        height: 1px;
        margin: 10px 0;
        margin-bottom: 20px;

        flex-basis: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: gray;
    }
    &__task {
        font-family: Roboto;
        background-color: rgb(39, 39, 55);
        width: auto;
        height: 30px;
        border-radius: 5px;
        color: white;
        padding: 2px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 5px;
        margin-left: 10px;
        box-shadow: 0px 0px 3px 2px rgba(226, 100, 229, 0.62);
        transition: max-width 0.3s ease, width 0.3s ease;

        & > p {
            font-size: 13px;
        }
    }
}