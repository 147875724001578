.route__shippmentAgent__importDocuments {
    font-family: "Roboto";
    color: white;
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow: auto;
    padding: 20px;
    cursor: default;

    &__wrap {
        background-color: #2E2E41;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.35);
        padding: 10px;
    
        & > h3 {
            margin-bottom: 10px;
        }
        &__item {
            display: grid;
            grid-template-columns: 150px 1fr;
            grid-template-rows: auto;
            align-items: center;
            gap: 20px;
            margin-bottom: 20px;

            & > textarea {
                background-color: #2E2E41;
                color: white;
                outline: none;
                border: 1px solid rgb(139, 136, 254);
                resize: none;
                height: 100px;
                padding: 10px;
            }
        }
        &__infoP {
            margin-top: 20px;

            &--error {
                color: #fb9393;
            }
            &--ok {
                color: #93fb98;
            }
        }
    }
}