.component__chat__wrap__content {
    width: 100%;
    height: 100%;
    min-height: 0;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 89px 1fr auto;
    border-left: 1px solid #525274;
    overflow: hidden;

    @media screen and (max-width: 890px) {
        width: 100vw;
    }
    &__header {
        width: 100%;
        height: 100%;
        background-color: #272737;
        border-bottom: 1px solid #525274;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 25px;
        
        &__left {
            width: auto;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            &__sidebar {
                width: 24px;
                height: 24px;
                outline: none;
                border: none;
                margin-right: 20px;
            }
            &__img {
                position: relative;
                width: 40px;
                height: 40px;
                margin-right: 16px;

                & > img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                &__activity {
                    position: absolute;
                    bottom: -1px;
                    right: -1px;
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid #272737;
                    transition: background-color 0.3s ease;
                }
            }
            &__info {
                width: auto;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                &__user {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: white;
                    display: flex;
                    align-items: center;

                    &__edit {
                        width: 20px;
                        height: 20px;
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }
                &__status {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #929292;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                &__statusMultiple {
                    width: auto;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &__usr {
                        position: relative;
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;

                        &__activity {
                            position: absolute;
                            bottom: -1px;
                            right: -1px;
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            border: 1px solid #272737;
                            transition: background-color 0.3s ease;
                        }
                        & > img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                }
                &__userCount {
                    font-family: "Roboto";
                    font-weight: bold;
                    font-size: 12px;
                    color: gray;
                    cursor: pointer;
                    transition: color 0.3s ease;

                    &:hover {
                        color: #6664E5;
                    }
                }
            }
        }
        &__right {
            width: auto;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
                width: 26px;
                height: 26px;
                margin-left: 30px;
                cursor: pointer;
            }
        }
        &__rename {
            position: absolute;
            top: 0;
            left: 0;
            width: 320px;
            height: 0;
            background-color: #454569;
            border-radius: 5px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 0px 19px;
            overflow: hidden;
            z-index: 3;
            transition: height 0.3s ease, padding 0.3s ease;

            &--active {
                padding: 24px 19px;
                height: 168px;
            }
            &__buttons {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 24px;
    
                &__button {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    background-color: #3D3D55;
                    border-radius: 53px;
                    padding: 7px 38px;
                    cursor: pointer;
                    transition: filter 0.3s ease;
    
                    &:first-child {
                        background-color: #6664E5;
                    }
                    &:hover {
                        filter: brightness(80%);
                    }
                }
            }
        }
    }
    &__chat {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 30px;
        overflow-y: auto;
        overflow-x: hidden;

        &__noChat {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(28, 28, 28, 0.55);

            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease, pointer-events 0.3s ease;

            & > p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 16px;
                margin: 10px 0px;
                color: #929292;
            }
        }
    }
    &__sendMessage {
        width: 100%;
        height: 100%;
        background-color: #272737;
        border-top: 1px solid #525274;
        height: 0;
        overflow: hidden;
        position: relative;
        display: grid;
        grid-template-rows: 1fr 20px;
        grid-template-columns: 1fr 100px;
        column-gap: 40px;
        row-gap: 15px;
        padding: 0 40px;
        transition: height 0.3s ease, padding 0.3s ease;

        &--active {
            height: 120px;
            padding: 20px 40px;
        }
        &__kanban {
            &.cloned-element .customComponents__kanban {
                z-index: 99;
            }
        }
        &__input {
            color: white;
            background-color: transparent;
            display: block;
            width: 100%;
            height: 100%;
            outline: none;
            border: 1px solid #525274;
            padding: 5px 20px;
            resize: none;
        }
        &__sendBtn {
            width: 100%;
            height: 100%;
            color: white;
            background-color: #6664E5;
            border-radius: 5px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__uploads {
            grid-row: 2;
            grid-column: 1 / span 2;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 20px;
            grid-auto-columns: 20px;
            grid-auto-flow: column;
            column-gap: 15px;

            & > img, & > div > img {
                height: 100%;
                width: 100%;
                transition: filter 0.3s ease;

                &:hover {
                    filter: brightness(0) saturate(100%) invert(39%) sepia(34%) saturate(2392%) hue-rotate(217deg) brightness(94%) contrast(90%);
                }
            }
        }
    }
    &__allUsers {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: white;
        cursor: default;

        &__wrap {
            width: 648px;
            height: auto;
            max-height: 100%;
            min-height: 0;
            overflow-y: auto;
            padding: 30px;
            background-color: #272737;

            @media screen and (max-width: 650px) {
                width: 100%;
            };
            &__head {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 140%;
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;

                &__btns {
                    display: flex;
                    align-items: center;

                    & > img {
                        width: 20px;
                        height: 20px;
                    }
                    & > div {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        margin-right: 20px;
                        padding: 10px;
                        background-color: #6664E5;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                }
            }
            &__users {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;

                &__user {
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;

                    &__left {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;

                        &__activity {
                            position: absolute;
                            bottom: -1px;
                            left: 26px;
                            display: block;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            border: 1px solid #272737;
                            transition: background-color 0.3s ease;
                        }
                        & > img {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                        }
                        &__wrap {
                            width: auto;
                            height: 36px;
                            min-height: 0;
                            display: flex;
                            justify-content: space-around;
                            flex-direction: column;
                            margin-left: 16px;

                            &__name {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 16px;
                            }
                            &__status {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                color: #929292;
                            }
                        }
                    }
                    &__right {
                        width: auto;
                        height: auto;

                        & > img {
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    &__addUser {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: white;
        cursor: default;

        &__wrap {
            width: 400px;
            height: auto;
            max-height: 100%;
            min-height: 0;
            overflow-y: auto;
            padding: 30px;
            background-color: #272737;

            &__buttons {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 24px;
    
                & > div {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    background-color: #3D3D55;
                    border-radius: 53px;
                    padding: 7px 38px;
                    cursor: pointer;
                    transition: filter 0.3s ease;
    
                    &:first-child {
                        background-color: #6664E5;
                    }
                    &:hover {
                        filter: brightness(80%);
                    }
                }
            }
        }
    }
}