* {
    scrollbar-width: none;
}
.component__dealReport {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 20px;
    position: relative;
    background-color: #272737;
    &__content {
        width: 100%;
        height: calc(100% - 250px);
        overflow: scroll;
    }
    @media screen and (max-width: 800px) {
        padding: 10px;
    }
    &__detailedOffer {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        margin: 20px 0;
        gap: 2px;
        & > p {
            background-color: #2e2e41;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-family: Roboto;
            text-align: center;
            line-height: 30px;
            padding: 10px;
            flex-direction: column;

            & > p > input {
                background-color: transparent;
                border: 1px solid white;
                height: 30px;
                width: 100px;
                margin: 0 5px;
                text-align: center;
                border-radius: 2px;
                padding: 3px;
                color: white;
            }
        }
        &__flexp {
            display: flex;
            flex-direction: column;
            & > p:first-child {
                border-bottom: 2px solid #272737;
                width: 100%;
                &::before {
                    content: "Novi upit: ";
                }
            }
            & > p:nth-child(2) {
                border-bottom: 2px solid #272737;
                width: 100%;
                &::before {
                    content: "Produzenje: ";
                }
            }
            & > p:nth-child(3) {
                width: 100%;
                &::before {
                    content: "Ukupno: ";
                }
            }
        }
    }
    &__kpis {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 20px 0;
        gap: 20px;
        @media screen and (max-width: 1100px) {
            grid-template-columns: repeat(2, 1fr);
        }
        & > div {
            border: 1px solid #5d6588;
            border-radius: 5px;
            padding: 20px;
            background-color: #2e2e41;
            & > p:first-child {
                font-family: Roboto;
                font-size: 18px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #737a99;
            }
            & > p:last-child {
                font-family: Roboto;
                font-size: 20px;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
                margin-top: 10px;
            }
        }
    }
    &__reports {
        &__filters {
            display: grid;
            grid-template-columns: 1fr 6fr;
            @media screen and (max-width: 1600px) {
                display: flex;
                gap: 20px;
                flex-direction: column;
            }
            & > p {
                font-family: Roboto;
                font-size: 32px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.20000000298023224px;
                text-align: left;
                color: white;
            }
            &__drops {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 20px;
                flex-wrap: wrap;

                @media screen and (max-width: 1100px) {
                    justify-content: flex-start;
                }
            }
            & > .customComponents__filterPanel {
                grid-column: span 2;
                background-color: transparent !important;
            }
            &__rangePicker {
                position: relative;
                & > p {
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #2e2e41;
                    color: white;
                    border-radius: 50px;
                    padding: 0 20px;
                    font-family: Roboto;
                    border: 1px solid #5d6588;
                }
                & > div {
                    overflow: hidden;
                    position: absolute;
                    top: calc(100% + 20px);
                    right: 0;
                    transition: all 0.3s ease;
                }
            }
        }
    }
    &__files {
        background-color: #2e2e41;
        border-radius: 5px;
        max-height: 400px;
        min-height: 400px;
        padding: 20px;
        &__buttons {
            position: relative;
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 20px;

            &__single {
                background-color: #5d6588;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 50px;
                border-top-left-radius: 100px;
                padding: 0 30px;
                cursor: pointer;
                font-family: Roboto;
                color: white;
                transition: all 0.3s ease;
                position: relative;
                margin-left: -10px;
                z-index: 1;
                &-active {
                    background-color: #2e2e41;
                    z-index: 2;
                }
            }
        }
    }
    &__agentList {
        @media screen and (max-width: 800px) {
            padding: 10px;
        }
        > h1 {
            font-family: Roboto;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.20000000298023224px;
            text-align: left;
            color: white;
            margin-bottom: 10px;
            & > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                & > div {
                    min-width: 300px;
                }
            }
        }
        &__single {
            display: grid;
            grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
            width: 100%;
            gap: 2px;
            margin-bottom: 2px;
            > p {
                width: 100%;
                font-family: Roboto;
                padding: 10px 20px;
                color: white;
                font-size: 16px;
                font-weight: 700;
                background-color: #2e2e41;
            }
        }
        &__singleHours {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100vh;
            &__container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                background-color: #272737;
                padding: 20px;
                border-radius: 5px;
                cursor: auto;
                max-height: 90%;
                overflow: auto;
                &>p{
                    color: white;
                    font-family: Roboto;
                    font-size: 22px;
                    margin-bottom: 20px;
                }
                &>button{
                    background-color: #5d6588;
                    margin-top: 20px;
                    padding: 10px 20px;
                    border-radius: 20px;
                    border: none;
                    cursor: pointer;
                    color: white;
                    font-family: Roboto;
                }
            }
        }
    }
    &__reportContainer__box {
        width: 100%;
        margin-top: 15px;
        padding: 30px;
        background-color: #2b2c3e;
        border-radius: 5px;
        box-shadow: 0px 0px 8px 0px rgba(102, 100, 229, 0.5);

        @media screen and (max-width: 1200px) {
            width: 100%;
        }
        @media screen and (max-width: 800px) {
            padding: 10px;
        }

        > p {
            border-bottom: 1px solid white;
            width: 100%;
            font-size: 16px;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 1fr 310px;
            color: white;

            > span {
                width: 100%;
                display: inline-block;
                border-right: 1px solid white;
                text-align: left;
                padding: 10px 0;
                @media screen and (max-width: 800px) {
                    width: 100px;
                    font-size: 12px;
                }
            }
            > strong {
                text-align: end;
                width: 100%;
                display: inline-block;
                padding: 10px 15px;
                @media screen and (max-width: 800px) {
                    width: calc(100% - 100px);
                }
            }
        }
        > p:last-child {
            border: none;
        }
    }
    &__agent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #272737;
        padding: 0 20px;
        @media screen and (max-width: 1200px) {
            padding: 0 5px;
        }
        > h1 {
            font-size: 20px;
            text-align: left;
            width: 20%;
            padding: 10px 0;
            padding-left: 15px;
            border-left: 1px solid white;
            @media screen and (max-width: 1200px) {
                font-size: 14px;
            }
        }
        > h1:first-child {
            border: none;
        }
        > p {
            border-left: 1px solid white;
            width: 20%;
            font-size: 16px;
            display: flex;
            height: 100%;
            padding: 10px 0;
            > strong {
                padding-left: 15px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 12px;
            }
        }
        > p:first-child {
            width: 20%;
            font-size: 20px;
            height: 100%;
            padding-left: 15px;
            border: none;
            @media screen and (max-width: 1200px) {
                font-size: 12px;
            }
            @media screen and (max-width: 600px) {
                padding-left: 5px;
            }
        }
        &__approve {
            position: relative;
            flex-direction: column;

            .customComponents__table & {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &__progress {
                    width: 100%;
                    margin: 6px 0;
                }
            }
            &__progress {
                margin-left: 15px;
                margin-top: 6px;
                width: calc(100% - 15px);
                height: 2px;
                position: relative;

                &__inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 0;
                }
            }
        }
    }
}
