.ccmanager__singleLead{
    width: 95%;
    height:95vh;
    background-color: #2b2c3e;
    margin: auto;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    @media screen and (max-width: 900px) {
       padding: 10px;
        
    }
    >h1{
        font-family: Roboto;
    }
    >h2{
        font-family: Roboto;
        margin-top: 10px;
    }
    >p{
        font-family: Roboto;
        font-size: 18px;
        margin-top: 10px;
    }
    &__buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 100%;
        >button{
            cursor: pointer;
            margin: 0 10px;
        }
    }
}
.ccmanager__history{
    width: 100%;
    height: 100% ;
    border-radius: 10px;
    margin: auto;
    overflow-x: hidden;
    background-color: #272737;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 30px;
    img{
        margin-right: 10px;
    }
    .component__agentCall__info__tabsContent__single__addComment{
        >textarea{
            background-color: transparent;
            color: white;
            border: 1px solid gray;
            outline: none;
        }
    }
    
    .component__agentCall__info__tabsContent__single__history{
        background-color: #37394b !important;
    }

}
.route__adminPanel__leads__display{
    width: 100%;
    background-color: #2b2c3e;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    >p{
        font-family: Roboto;
        font-size: 18px;
        margin: 0 10px;
        padding: 0 3px;
        border-bottom: 1px solid gray;
        cursor: pointer;
    }
}