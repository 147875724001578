.component__dealShipmentHistory {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px;
    cursor: default;
    overflow-y: auto;

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & .customComponents__buttonSmall {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }
    &__shipment {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &__editBtn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            & > p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                color: #999fae;
                cursor: pointer;
            }
        }
    }
}
.modal__editDealShipment{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &__container{
        background-color: #34384C;
        padding: 20px;
        border-radius: 5px;
        width: 500px;
        &>p{
            color: white;
            font-family: Roboto;
            margin-bottom: 10px;
            text-align: center;
        }
        &__buttons{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            margin-top: 20px;
            &>p{
                padding: 10px;
                color: white;
                font-family: Roboto;
                cursor: pointer;
                border-radius: 5px;
                &:first-child{
                    background-color: #6664e5;
                }
                &:last-child{
                    background-color: red;
                }
            }
        }
    }

}