:root {
 --customComponents__audioPlayer: black;       
}

.customComponents__audioPlayer {
    all: initial;
    width: 100%;
    height: auto;
    background-color: #EEEEF4;
    color: black;
    border: 1px solid gray;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 50px 1fr;
    column-gap: 20px;
    place-items: center;
    padding: 0px 10px;
    border-radius: 5px;
    font-family: "Roboto";
    position: relative;

    &__btns {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & > svg {
            width: 100%;
            height: 100%;
            cursor: pointer;
            fill: var(--customComponents__audioPlayer)
        }
        & > p {
            font-size: 11px !important;
            padding: 10px;
            font-weight: bold;
            color: var(--customComponents__audioPlayer) !important;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #434295;
            }
        }
    }
    &__controls {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr;
        justify-content: flex-start;
        align-items: center;
        column-gap: 30px;

        &__surfer {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 10px 0px;
            padding-bottom: 2px;

            & > wave {
                height: 50px !important;
            }
            &__overlay {
                position: relative;
                width: 100%;
                height: 10px;
                
                & > div {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0px;
                    height: 10px;
                    z-index: 3;
                    opacity: 0.5;
                    transition: height 0.3s ease, opacity 0.3s ease;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
            &__playbackSpeedBtns {
                width: 100%;
                height: auto;
                margin-top: auto;
                margin-bottom: 5px;

                &__btn {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                    border: 1px solid #999FAE;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 3px;
                    padding: 5px 15px;
                    margin-right: 10px;
                    cursor: pointer;
                    transition: color 0.3s ease, border 0.3s ease;

                    &--active {
                        color: #e100ff !important;
                        border-color: #d515ee;
                    }
                }
            }
        }
        &__slider {
            width: auto;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            & > p {
                cursor: default;
                font-family: "Roboto";
            }
            &__audio {
                margin-left: 15px;
                width: 50px;
                height: 100%;
                padding: 10px;

            }
            &__audio:hover + &__audioPopout {
                max-width: 200px;
                padding-left: 10px;
            }
            &__audioPopout {
                position: absolute;
                z-index: 99;
                top: 0;
                right: 55px;
                width: 100%;
                max-width: 0;
                height: 100%;
                background-color: #EEEEF4;
                overflow: hidden;
                padding-left: 0;

                display: flex;
                justify-content: center;
                align-items: center;
                transition: max-width 0.3s ease, padding-left 0.3s ease;

                &:hover {
                    padding-left: 10px;
                    max-width: 200px;
                }
                & > input[type=range] {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.customComponents__audioPlayer--dark.customComponents__audioPlayer {
    background-color: #2B2D3E;
    color: white;
    
    & .customComponents__audioPlayer__controls__slider {
        &__audioPopout {
            background-color: #2B2D3E;
        }
    }
}