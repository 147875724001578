.component__agentSector {
    width: 100%;
    height: 100%;
    background-image: url("../../../public/images/bcg7.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 55px 395px;
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        & > p {
            font-family: Poppins;
            font-size: 22px;
            font-weight: 600;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            color: white;
        }
        & > div {
            > img:first-child {
                height: 67px;
                width: 67px;
            }
        }
    }
    &__container {
        width: 100%;
        background-color: #3d3d55;
        padding: 70px 50px;
        border-radius: 5px;
        &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > p {
                font-family: Roboto;
                font-size: 22px;
                font-weight: 600;
                line-height: 31px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
            }
        }
        &__progres {
            width: 80px;
            height: 5px;
            background-color: white;
            border-radius: 5px;
            & > div {
                width: 50%;
                height: 100%;
                border-radius: 2px;
                background-color: #6664e5;
            }
        }
        &__border {
            width: 80px;
            border-radius: 2px;
            border-bottom: 2px dashed white;
        }
        &__single {
            margin-top: 32px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            & > img {
                margin-right: 32px;
            }
            & > p {
                font-family: Roboto;
                font-size: 22px;
                font-weight: 600;
                line-height: 31px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
                margin-right: 40px;
            }
            & > div {
                & > h4 {
                    font-family: Roboto;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 25px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: white;
                    margin-top: 5px;
                }
                & > div {
                    display: flex;
                    margin-top: 20px;
                    & > div {
                        margin-right: 60px;
                        & > p {
                            font-family: Roboto;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 25px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: white;
                            margin-bottom: 15px;
                        }
                        & > ul {
                            margin-left: 20px;
                            & > li {
                                font-family: Roboto;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 22px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
