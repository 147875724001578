.component__agentHelper__products {
    margin: 20px;
    background-color: gray;
    width: 100%;
    max-height: calc(100% - 150px);
    overflow: auto;
    transition: all 0.3s ease;
    & > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 1px;
        margin: 1px;
        & > p {
            background-color: #272737;
            font-family: Roboto;
            padding: 10px 20px;
            text-align: center;
        }
        &:first-child{
            &>p{
                background-color: #505097;
                font-weight: 900;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
            }
        }
    }
}
.component__agentHelper__scripts {
    width: 100%;
    max-height: calc(100% - 150px);
    overflow: auto;
    transition: all 0.3s ease;
    &__content {
        padding: 20px;
        overflow: auto;
        & > * {
            font-family: Roboto;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 10px;
        }
        & p{
            margin-bottom: 10px;
        }
        & h1{
           margin-bottom: 10px;
           margin-top: 10px;
           font-size: 30px;
        }
        & li{
            font-size: 20px;
        }
        &__table{
            display: grid;
            grid-template-columns: 1fr 1fr;
            background-color: white;
            padding: 2px;
            gap: 2px;
            &>div{
                display: grid;
                gap: 2px;
                &>p{
                    margin: 0;
                    background-color: #272737;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }
}
.component__agentHelper__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    & > p {
        padding: 10px;
        border: 1px solid gray;
        border-radius: 5px;
        cursor: pointer;
    }
}
