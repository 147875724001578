.ccmanager__singleLead {
    color: white;
    font-family: "Roboto";
    cursor: default;

    .customComponents__table__data__groupNewline > p {
        max-width: 100%;
    }
    &__buttons {
        padding: 0px 30px;
        overflow-y: auto;

        & > button {
            position: relative;
            margin: 0 !important;
            background-color: #3d3d55 !important;
            border-radius: 0;
            height: 50px;
            width: auto !important;
            font-size: 15px;
            padding: 0px 20px;
            border-right: 1px solid #969698;

            &:last-child {
                border-right: 0px;
            }
            &::after {
                content: "";
                position: absolute;
                bottom: 1px;
                left: 50%;
                transform: translateX(-50%);
                height: 2px;
                width: 0%;
                z-index: 9;
                background-color: #6664e5;
                transition: width 0.3s ease;
            }
            &.active::after {
                width: 80%;
            }
        }
    }

    &__saveDeal {
        position: relative;
        color: white;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 10px 30px;
        font-size: 15px;

        & > p {
            text-align: start;
        }
        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #3d3d55a8;
            opacity: 0;
            position: none;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: opacity 0.3s ease;
        }
    }
    &__smsTemplates {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        &__buttons {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: flex-end;

            & > * {
                margin-left: 10px;
            }
        }
        & .customComponents__table {
            display: block;
        }
    }
}

.component__agentCall__info__tabsContent__single__history {
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: transparent;
    border: 1px solid #aca9a9;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 3px;
    margin-top: 5px;
    position: relative;
    &__user {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        width: auto;
        height: 100%;

        &__info {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            flex-direction: column;
            height: 64px;

            & > p {
                font-family: Roboto;
                font-size: 18px;
                font-weight: 600;
                flex-grow: 0;
                flex-shrink: 0;
                color: white;

                & > span:first-child {
                    display: block;
                    font-weight: 400;
                    color: rgb(182, 182, 182);
                    margin-bottom: 5px;
                    text-align: start;
                }
            }
        }
        & > p {
            font-family: Roboto;
            font-size: 18px;
            font-weight: 600;
            flex-grow: 0;
            flex-shrink: 0;
            color: white;
            & > span:first-child {
                display: block;
                font-weight: 400;
                color: rgb(182, 182, 182);
                margin-bottom: 5px;
            }
        }
        & > img {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin-right: 20px !important;
        }
    }
    &__content {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 10px;
        padding-left: 85px;

        & > p {
            font-family: "Roboto";
            font-size: 15px;
            word-break: break-word;
            width: 100%;
            color: white;
            user-select: none;
        }
        &__date {
            font-family: "Roboto";
            font-size: 14px;
            color: #999fae;
        }
        & > audio {
            width: 100%;
        }
    }
}

.ccmanager__singleLead__box {
    background-color: #3d3d55;
    width: 100%;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    cursor: default;
    position: relative;

    & .customComponents__input {
        width: 100%;
        min-height: 35px;

        & > input {
            font-size: 18px;
            font-family: "Montserrat";
            padding: 0;
            top: -8px !important;
        }
    }
    & .customComponents__buttonSmall {
        transition: background-color 0.3s ease;
    }
    & .component__customComponent__dropbox {
        height: 35px;
        width: 100%;
    }
    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #3d3d55a8;
        opacity: 0;
        position: none;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.3s ease;
    }
    > p {
        text-align: left;
        font-size: 18px;
        min-height: 34px;
        user-select: all;
    }
    > h1 {
        text-align: left; 
    }
    > small {
        color: #999fae;
        margin-top: 10px;
        font-size: 12px;
        margin-bottom: 5px;
    }
    &__statusDisplay {
        width: 100%;
        height: 5px;
        display: grid;
        grid-template-rows: 1fr;
        column-gap: 5px;

        > span {
            border-radius: 5px;
        }
    }
    &__article {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-top: 1px solid white;
        height: 50px;
        width: 90%;
        > p {
            text-align: left;
            font-size: 18px;
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
    }
    &__editBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        padding: 5px;
        border-radius: 5px;
        transition: right 0.3s ease, background-color 0.3s ease;

        &:hover {
            background-color: #5b5b75;
        }
        & > img {
            margin-right: 12px;
            width: 20px;
            height: 20px;
        }
        & > p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #999fae;
        }
    }
    &__editBtns {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 0px;
        max-height: 0px;
        overflow: hidden;
        pointer-events: none;
        transition: margin-top 0.3s ease, max-height 0.3s ease;
    }
    &__dateTimePicker {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        & > .customComponents__input {
            width: calc(50% - 10px);

            & > input {
                width: 100%;
            }
        }
    }
}
.ccmanager__singleLead__container {
    width: 100%;
    height: 100%;
    display: flex;

    > div {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: all 0.3s ease;
        @media screen and (max-width: 900px) {
            width: 100% !important;
            height: auto;
        }
    }
    & .component__dealShipmentHistory__buttons {
        display: none;
    }
    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        overflow-y: scroll;
    }
}
.ccmanager__singleLead__buttons {
    &__activeTabContainer {
        height: calc(100% - 100px);
        padding-top: 20px;
    }
    &__exitBtn {
        position: sticky;
        right: -30px;
        height: 50px;
        margin-left: auto;

        & > button {
            height: 100%;
        }
    }
}
.ccmanager__history {
    &__SMSTemplate {
        height: 50px;
        margin-left: auto !important;
        display: flex;
        align-items: center;
    }
    &__callControl {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        margin-left: -20px;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 104px 1fr;

        &__badge {
            position: absolute;
            top: 10px;
            right: 0;
            width: 24px;
            height: 24px;
        }
        &__wrap {
            width: 100%;
            height: auto;
            position: relative;

            &__top {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                align-items: center;

                &__img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
                & > p {
                    font-weight: bold;
                }
            }
            &__bottom {
                margin-top: 20px;

                &__rating {
                    display: grid;
                    grid-template-rows: max-content;
                    grid-template-columns: 95px repeat(5, 1fr);
                    justify-content: center;
                    align-items: center;
                    column-gap: 10px;
                    margin-bottom: 10px;

                    & > p {
                        padding-right: 10px;
                        text-align: left;
                    }
                    &__item {
                        display: block;
                        width: 100%;
                        height: 2px;
                        border-radius: 50px;
                        background-color: rgb(84, 84, 84);

                        &--active {
                            background-color: rgb(158, 157, 224);
                        }
                    }
                }
                &__comment {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    &__head {
                        font-weight: bold;
                    }
                    & > p {
                        text-align: start;
                    }
                }
            }
        }
    }
}
.ccmanager__singleLead__container .ccmanager__history .leadMoreInfoHistory {
    background-color: transparent !important;
}
.ccmanager__singleLead__container__history__button {
    width: 100%;
    text-align: left;
    padding-left: 30px;
    text-decoration: underline;
}
.component__agentCall__info__tabsContent__single__history__content__activity {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;

    & > img {
        margin: 0 !important;
    }
}
.ccmanager__singleLead__box__pill {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: auto;
        height: 20px;
        font-size: 12px;
        border-radius: 5px;
        background-color: #434295;
        border: 1px solid #5654ba;
    }
}
.changeArticles {
    height: 90%;
    overflow-y: scroll;
    position: relative;

    & .customComponents__table {
        display: block;
        width: calc(100% - 45px);
        margin: 0 auto;
    }
}

.ccmanager__singleLead__documents {
    width: 100%;
    height: calc(100% - 30px);
    padding: 0 30px;
    margin-top: 20px;
    justify-content: flex-start;
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 1fr;

    & > .ccmanager__singleLead__buttons {
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;

        & .customComponents__buttonSmall {
            background-color: #37374b !important;
        }
    }
    &__frame {
        width: 100%;
        min-height: 64px;
        overflow: auto;
        position: relative;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }
        & > iframe {
            width: 100%;
            height: calc(100% - 10px);
            outline: none;
            border: none;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }
    }
}

.ccmanager__singleLead__box__additionalData {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;

    &__item {
        width: 100%;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr) 100px;
        column-gap: 15px;
    }
}

.component__sendMessageModal {
    width: 30%;
    margin: auto;
    background-color: #37374b;
    max-height: 90vh;
    overflow: hidden scroll;
    margin-top: 5vh;
}
.component__sendMessageModal * {
    color: white;
}
.component__sendMessageModal .customComponents__buttonSmall {
    margin: 20px;
}
.customComponents__table__data__customInput * {
    user-select: all !important;
}

.component__warning {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .component__customComponent__dropbox {
        width: 100% !important;
    }
    > p {
        width: 100%;
        text-align: left;
        margin: 10px;
    }
    > textarea {
        width: 100%;
        height: 400px;
        border-radius: 5px;
        background-color: transparent;
        margin-bottom: 10px;
        padding: 10px;
        color: white;
    }
}

.ccmanager__singleLead__importedDocuments {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow-x: hidden;
    background-color: #272737;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 30px;

    &__createdUser {
        display: flex;
        align-items: center;
        margin-top: 10px;

        & > img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: 0 10px;
        }
    }
}

.ccmanager__singleLead__createReclamation{
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    gap: 10px;
    &>p{
        font-size: 18px;
        font-family: Roboto;
    }
    &>input{
        width: 100%;
        height: 46px;
        background-color: transparent;
        border: 1px solid #3d3d55;
        outline: none;
        border-radius: 3px;
        padding: 10px;
        color: white;
        font-family: Roboto;
    }
    &>textarea{
        width: 100%;
        height: 146px;
        background-color: transparent;
        border: 1px solid #3d3d55;
        outline: none;
        border-radius: 3px;
        padding: 10px;
        color: white;
        font-family: Roboto;
    }


}