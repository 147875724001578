.components__firstTimeGreetings {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    z-index: 99;

    &__wrap {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        padding-top: 70px;
        position: relative;
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: repeat(2, 1fr);


        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #0009;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            & > p {
                margin-left: 16px;
                font-family: 'Russo One';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 36px;
                text-transform: uppercase;
                color: rgb(165, 163, 163);
                margin-top: 10px;
            }
        }
        &__close {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;
        }
        &__head {
            width: 100%;
            height: auto;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            place-items: center;
            grid-row: 1;
            grid-column: 1 / span 2;
            
            &__container {
                display: flex;
                align-items: center;

                & > span {
                    margin-left: 16px;
                    font-family: 'Russo One';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 36px;
                    text-transform: uppercase;
                    color: #535353;
                }
            }
        }
        &__left {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &__text {
                width: 100%;
                max-width: 520px;
                font-family: 'Russo One';
                font-style: normal;
                font-weight: 400;
                font-size: 100.833px;
                line-height: 122px;
                text-transform: uppercase;
                color: #4F4F4F;
            }
            &__desc {
                width: 100%;
                max-width: 520px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #4F4F4F;
                word-break: break-word;
            }
        }
        &__right {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            flex-direction: column;

            & > *:nth-child(2) {
                margin-left: 15%;

                @media screen and (max-width: 1400px) {
                    margin-left: 0;
                }
            }
        }
        &__infoP {
            margin-left: 16px;
            font-family: 'Russo One';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            text-transform: uppercase;
            color: #535353;
            text-align: center;
        }
    }
    &__colorBox {
        min-width: 50px;
        width: auto;
        border-radius: 5px;
        padding: 7px;
        font-family: 'Russo One';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        text-transform: uppercase;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &--small {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            padding: 5px;
            min-width: 34px;
            min-height: 34px;
        }
    }
    &__agentBox {
        width: 508px;
        height: 222px;
        border-radius: 5px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &__heart {
            width: 54px;
            height: 54px;
            position: absolute;
            top: 0;
            right: -16px;
            transform: translateX(100%);
            cursor: pointer;

            &--active {
                cursor: default;
                filter: brightness(0) invert(43%) sepia(53%) saturate(4347%) hue-rotate(300deg) brightness(102%) contrast(104%);
            }
        }
        &__like {
            width: 54px;
            height: 54px;
            position: absolute;
            top: 50%;
            right: -16px;
            transform: translateX(100%) translateY(-50%);
            cursor: pointer;

            &--active {
                cursor: default;
                filter: brightness(0) invert(43%) sepia(53%) saturate(4347%) hue-rotate(300deg) brightness(102%) contrast(104%);
            }
        }
        &__inner {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 162px 1fr;
            column-gap: 18px;
            background-color: #F8F8F8;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

            &__img {
                width: 124px;
                height: 124px;
                justify-self: flex-end;
                align-self: center;
                position: relative;
    
                &__counters {
                    position: absolute;
                    bottom: -35px;
                    left: 50%;
                    width: auto;
                    white-space: nowrap;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & > img {
                        width: 16px;
                        height: 16px;
                        margin-right: 7px;
                        
                        &:nth-child(3) {
                            filter: brightness(0) invert(43%) sepia(53%) saturate(4347%) hue-rotate(300deg) brightness(102%) contrast(104%);
                        }
                    }
                    & > span {
                        color: #535353;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        margin-right: 21px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                & > img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 138px;
                    height: 138px;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    border: 2px solid #54A1FF;
                }
            }
            &__right {
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-rows: 41px 1fr;
                grid-template-columns: 1fr;
                row-gap: 13px;
                padding: 20px;
    
                &__name {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 41px;
                    color: #FF3DB8;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-word;
                }
                &__info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: flex-start;
    
                    & > p {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
    
                        & > span {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 25px;
                            color: #535353;
                        }
                    }
                }
            }
        }
    }
}