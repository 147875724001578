.components__tasks {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #272737;
    color: white;
    padding: 50px;
    opacity: 0;

    display: grid !important;
    grid-template-rows: 24px 1fr !important;
    grid-template-columns: 1fr !important;
    justify-content: initial !important;
    align-items: initial !important;
    @media screen and (max-width: 900px) {
        padding: 30px 10px;
    }
    &__close {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: flex-end;
        & > img {
            width: 24px;
            height: 24px;
        }
    }
    &__wrap {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 250px 1fr;
        grid-column-gap: 50px;
        @media screen and (max-width: 1400px) {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            grid-column-gap: 20px;
            padding-top: 10px;
            overflow-y: scroll;
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            overflow: visible;

            &__toggle {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                &__main {
                    width: 100%;
                    height: 39px;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 20px;
                    margin: 6px 0px;
                    cursor: pointer;
                    background-color: #0d99ff;

                    &__imgMain {
                        width: 16px;
                        height: 16px;
                        margin-right: 12px;
                        transform: rotate(45deg);
                    }
                    &__img {
                        margin-left: auto;
                        transform: rotate(0deg);
                        transform-origin: center;
                        width: 32px;
                        height: 32px;
                        filter: brightness(0) invert(1);
                        transition: transform 0.3s ease;
                    }
                    & > span {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 140%;
                    }
                    &.active &__img {
                        transform: rotate(180deg);
                    }
                }
                &__side {
                    width: 100%;
                    height: 0;
                    overflow: hidden;
                    transition: height 0.3s ease;

                    &.active {
                        height: 200px;
                    }
                    &__btn {
                        width: 100%;
                        height: 39px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 20px;
                        margin: 6px 0px;
                        cursor: pointer;

                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: #3d3d55;
                        }
                        & > img {
                            width: 16px;
                            height: 16px;
                            margin-right: 12px;
                        }
                        & > span {
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 140%;
                        }
                    }
                }
            }
        }
        &__right {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            position: relative;
            @media screen and (max-width: 900px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: auto;
            }

            & > * {
                transform: scale(1);
                opacity: 1;
                transition: transform 0.3s 0.1s ease, opacity 0.3s ease;
            }
            &--animateOut {
                & > * {
                    transform: scale(0);
                    opacity: 0;
                    transition-delay: 0s;
                }
            }
            &__modal {
                position: absolute;
                top: 0;
                right: -150%;
                width: 100%;
                height: 100%;
                padding: 30px;
                background-color: #3d3d55;
                transform: scale(1);
                opacity: 1;
            }
        }
    }

    &__taskItem {
        width: 100%;
        height: 100%;
        background-color: #3d3d55;
        padding: 30px;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 20px 1fr;
        grid-template-columns: 1fr;
        row-gap: 20px;
        position: relative;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #3d3d55a8;
            opacity: 0;
            position: none;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: opacity 0.3s ease;
        }
        &__heading {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__left {
                display: flex;
                justify-content: center;
                align-items: center;

                & > p {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                }
                & > * {
                    margin-right: 15px;
                }
            }
            &__right {
                position: relative;

                & > * {
                    margin-left: 15px;
                }
                &__mask {
                    width: 20px;
                    height: 20px;
                    background-color: white;
                    transition: background-color 0.3s ease;

                    &.cloned-element {
                        mask: unset !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: black;
                    }
                    &:hover {
                        background-color: #0d99ff;
                    }
                }
                &__dots {
                    cursor: pointer;
                    color: white;
                    transition: color 0.3s ease;

                    &:hover {
                        color: #0d99ff;
                    }
                }
                &__dotsItems {
                    position: absolute;
                    top: 20px;
                    right: 0;
                    width: 150px;
                    height: auto;
                    max-height: 0;
                    overflow: hidden;
                    background-color: #525274;
                    border-radius: 3px;
                    padding: 0px 16px;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    transition: max-height 0.3s ease, padding 0.3s ease;

                    & > div {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        margin-bottom: 12px;
                        cursor: pointer;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    &.active {
                        max-height: 85px;
                        padding: 16px;
                    }
                }
            }
        }
    }
    &__scratchpad {
        transform-origin: top right;

        &__text {
            background-color: transparent;
            outline: none;
            border: none;
            color: white;
            resize: none;
        }
        &__addNote {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &__wrap {
                position: relative;
                padding: 30px;
                width: 400px;
                height: auto;
                background-color: #272737;

                &__close {
                    text-align: right;
                    height: 24px;
                    margin-bottom: 18px;

                    & > img {
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }
                }
                &__buttons {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;
                }
                & > textarea {
                    margin-top: 20px;
                    padding: 5px;
                    color: white;
                    border: 1px solid #bebebe;
                    width: 100%;
                    height: 200px;
                    background-color: transparent;
                    outline: none;
                }
                &__spinner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #2b2c3eb5;
                    margin: 0 !important;
                    z-index: 999;
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity 0.3s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    &__allTasks {
        transform-origin: bottom left;

        &__tasks {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &--expanded {
                overflow-y: auto;
            }
            &__wrap {
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: 1fr;

                &--expand {
                    grid-template-rows: max-content;
                    grid-auto-rows: max-content;
                    row-gap: 20px;
                    position: relative;
                }
                &__task {
                    width: 100%;
                    height: auto;
                    display: grid;
                    grid-template-rows: 22px 17px;
                    grid-template-columns: 20px 1fr;
                    column-gap: 16px;
                    row-gap: 8px;
                    cursor: pointer;

                    &--finished &__dot {
                        border: none;
                        background-size: 100% 100%;
                    }
                    &__dot {
                        grid-row: 1;
                        grid-column: 1;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        border: 1px solid white;
                        transition: background-color 0.3s ease;
                    }
                    &__name {
                        grid-row: 1;
                        grid-column: 2;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        width: 100%;
                        height: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 100%;
                        min-width: 0;
                        overflow: hidden;
                    }
                    &__date {
                        grid-row: 2;
                        grid-column: 2;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        color: #999fae;

                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        &--due {
                            color: #e9568b;

                            & > img {
                                filter: brightness(0) saturate(100%) invert(46%) sepia(69%) saturate(473%)
                                    hue-rotate(287deg) brightness(92%) contrast(104%);
                            }
                        }
                        & > img {
                            width: 16px;
                            height: 16px;
                            margin-right: 12px;
                            transition: filter 0.3s ease;
                        }
                    }
                }
            }
        }
    }
    &__singleTask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;

        &__wrap {
            position: relative;
            width: 662px;
            height: auto;
            max-height: 100%;
            padding: 30px;
            background-color: #272737;
            color: white;
            position: relative;
            overflow-y: auto;

            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #2b2c3eb5;
                margin: 0 !important;
                z-index: 999;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &__close {
                width: 100%;
                height: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                & > img {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }
            }
            &__head {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__text {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                }
                &__action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    & > img {
                        width: 24px;
                        height: 24px;
                        margin-right: 14px;
                    }
                    & > span {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                    }
                }
            }
            &__dates {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 15px;
                color: #999fae;

                &__wrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & > img {
                        width: 24px;
                        height: 24px;
                        filter: brightness(0) saturate(100%) invert(75%) sepia(17%) saturate(237%) hue-rotate(185deg)
                            brightness(84%) contrast(81%);
                    }
                    & > span {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    &--expired {
                        filter: brightness(0) saturate(100%) invert(67%) sepia(61%) saturate(5954%) hue-rotate(308deg)
                            brightness(97%) contrast(88%);
                        & > img {
                            filter: none;
                        }
                    }
                }
            }
            &__name {
                margin-top: 20px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
            }
            &__description {
                margin-top: 10px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #bebebe;
                white-space: pre-wrap;
                word-break: break-word;
            }
            &__assignedUsers {
                margin-top: 21px;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;

                &__wrap {
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &__date {
                        display: flex;
                        align-items: center;

                        & > img {
                            width: 24px;
                            height: 24px;
                            filter: brightness(0) saturate(100%) invert(75%) sepia(17%) saturate(237%)
                                hue-rotate(185deg) brightness(84%) contrast(81%);
                        }
                        & > span {
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 140%;
                            color: #999fae;
                        }
                    }
                    &__usr {
                        display: flex;
                        align-items: center;

                        & > img {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                        }
                        & > span {
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }
                &__text {
                    color: #bebebe;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 12px;
                }
                &__users {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;

                    &__user {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 40px;
                        margin-bottom: 20px;

                        & > img {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        & > span {
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }
            }
            &__subtasks {
                width: 100%;
                height: auto;

                &__text,
                &__textErr {
                    color: #bebebe;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                }
                &__text {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    & > span {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & > img {
                            margin-right: 10px;
                            width: 16px;
                            height: 16px;
                        }
                        &:nth-child(2) {
                            transition: color 0.3s ease;

                            &:hover {
                                color: #0d99ff;
                            }
                        }
                    }
                    &__addSubtask {
                        &.cloned-element {
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                &__textErr {
                    margin-top: 10px;
                    color: white;
                }
                &__tasks {
                    width: 100%;
                    height: auto;

                    & .components__tasks__allTasks__tasks__wrap__task {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    &__singleNote {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center;
        cursor: default;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &__wrap {
            width: 400px;
            height: auto;
            max-height: 100%;
            overflow-y: auto;
            padding: 30px;
            background-color: #272737;
            border-radius: 5px;
            position: relative;

            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #3d3d55a8;
                opacity: 0;
                position: none;
                z-index: 99;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: opacity 0.3s ease;
            }
            &__close {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 24px;

                & > img {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }
            }
            &__btns {
                margin: 10px 0px;
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 16px;
                width: 100%;

                &__pin,
                &__del {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & > img {
                        width: 24px;
                        height: 24px;
                        transform-origin: center;
                    }
                    & > span {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: white;
                    }
                }
            }
            & > h3 {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: white;
                margin-bottom: 10px;
                margin-top: 30px;
            }
            & > p {
                color: white;
                white-space: pre-wrap;
                color: #bebebe;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
            }
        }
    }
    &__addTask {
        color: white;
        background-color: #272737;
        width: 480px;
        height: auto;
        min-height: 400px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        position: relative;

        &--wrap {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #3d3d55a8;
            opacity: 0;
            position: none;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: opacity 0.3s ease;
        }
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            & > img {
                width: 20px;
                height: 20px;
            }
        }
        &__text {
            margin-top: 20px;
            padding: 5px;
            color: white;
            border: 1px solid #bebebe;
            width: 100%;
            height: 100px;
            background-color: transparent;
            outline: none;
        }
        &__input {
            color: white;
            width: 100%;
            height: 30px;
            padding: 5px 0px;
            margin: 10px 0px;
            display: flex;
            cursor: pointer;
            background-color: transparent;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #3d3d55;
            }
            & > img {
                width: 20px;
                height: 20px;
                margin-right: 15px;
            }
            & > p {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
            }
        }
        &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }
        &__dateTimeSelect {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            background-color: #272737;
            width: 480px;
            height: 225px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            & > div {
                margin-bottom: 15px;

                & > .customComponents__input {
                    margin-bottom: 15px;
                }
            }
        }
        &__assignedUsers {
            color: white;
            background-color: #272737;
            width: 480px;
            height: auto;
            padding: 30px;
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            &__dropdown {
                display: grid;
                grid-template-rows: 1fr;
                grid-template-columns: 1fr 100px;
                justify-content: center;
                align-items: center;
                column-gap: 10px;

                & .customComponents__buttonSmall {
                    height: 100%;
                }
            }
            &__users {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                &__user {
                    width: 100%;
                    height: auto;
                    margin: 10px 0px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: "Roboto";

                    & > img:nth-child(1) {
                        width: 32px;
                        height: 32px;
                        padding: 8px;
                        margin-right: 15px;
                    }
                    & > img:nth-child(2) {
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    &__allNotes {
        transform-origin: bottom right;

        &__notes {
            display: flex;
            justify-content: center;
            align-items: center;

            &__wrap {
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-rows: 1fr;
                grid-template-columns: repeat(auto-fit, 190px);
                column-gap: 16px;
                @media screen and (max-width: 900px) {
                    grid-template-columns: repeat(auto-fit, 100%);
                        
                }

                &__note {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 20px auto 1fr;
                    row-gap: 10px;
                    background-color: #525274;
                    border-radius: 5px;
                    padding: 20px 16px;
                    overflow: hidden;
                    cursor: pointer;

                    &.cloned-element {
                        color: white;
                    }
                    
                    & > p {
                        white-space: wrap;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 140%;
                        overflow: hidden;
                    }
                    & > h3 {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 140%;
                    }
                    & > div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        & > img {
                            width: 16px;
                            height: 16px;
                        }

                        & > span {
                            color: #bebebe;
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                        }
                    }
                }
            }
            &__wrapExpand {
                display: grid;
                grid-template-rows: 100px;
                grid-auto-rows: 100px;
                grid-template-columns: 1fr;
                row-gap: 20px;
                width: 100%;
                height: 100%;
                background-color: transparent;

                &__note {
                    display: grid;
                    grid-template-rows: repeat(3, 1fr);
                    grid-template-columns: 16px 1fr;
                    column-gap: 15px;
                    row-gap: 12px;
                    cursor: pointer;

                    &__name,
                    &__description,
                    &__date {
                        grid-column: 2;
                    }
                    &__img {
                        margin-top: 4px;
                    }
                    &__name {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 140%;
                    }
                    &__description {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 140%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    &__date {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 140%;
                        color: #bebebe;
                    }
                }
            }
        }
    }
    &__pinnedNotes {
        transform-origin: top left;
    }
}
