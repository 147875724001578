.component__chat {
    all: initial;
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    border: none;
    outline: none;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    position: relative;
    z-index: 999;

    &__wrap {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: auto 1fr;
    }
}