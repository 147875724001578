.route__adminPanel__articles {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    cursor: default;
    overflow-y: auto;
    padding: 10px;

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        & > * {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }
    &__articleImage {
        width: 200px;

        & > img {
            width: 100%;
            height: auto;
            max-width: 70px;
        }
    }
    &__addArticle, &__articleCompounds, &__articleHistory {
        background-color: #2b2c3e;
        padding: 20px 40px;
        width: 400px;
        margin: 0 auto;
        cursor: default;
        position: relative;
        max-height: 100%;
        overflow-y: auto;

        &--info {
            & .components__customComponents__checkbox {
                width: 100%;
                margin-top: 10px;
            }
        }
        &__materials {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;            

            &__item {
                width: 100%;
                height: auto;

                display: grid;
                grid-template-columns: 1fr 70px;
                grid-template-rows: auto;
                align-items: center;
                gap: 10px;
            }
        }
        &__description {
            width: 100%;
            height: auto;
            min-height: 300px;
            margin-top: 10px;
            border: 1px solid gray;
            outline: none;
            background-color: #2b2c3e;
            color: white;
            padding: 10px;
        }
        & .customComponents__buttonSmall {
            margin: 20px;
        }
        & > div {
            margin: 30px 0px;
        }
        &__infoP {
            color: #d82626;
        }
        &__btnWrap {
            display: flex;
            align-items: center;
            margin: 10px 0 !important;
        }
        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #2b2c3e;
            margin: 0 !important;
            z-index: 999;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__img {
            width: 100%;
            height: auto;
            max-height: 200px;

            & > div {
                width: 100%;
                height: 200px;
                background-size: 100% 100%;
                position: relative;

                & > p {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    background-color: #0000009c;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: opacity 0.3s ease;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        &__articleContents {
            width: 100%;       
            height: 300px;
            color: white;
            background-color: #222331;
            outline: none;
            border: 1px solid gray;
            padding: 5px;
        }
    }
    &__editArticle {
        font-size: 12px;
        width: 300px;
        position: relative;

        & > * {
            margin: 20px;
            margin-left: 0;
        }
        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #2b2c3e;
            margin: 0 !important;
            z-index: 999;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__infoP {
            color: #d82626 !important;
        }
    }
    &__removeArticle {
        & > * {
            margin: 10px;
            margin-left: 0;
        }
    }
    &__articleCompounds, &__articleHistory {
        width: 100%;
        height: 100%;
        background-color: transparent;
        overflow-y: auto;

        &__buttons {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: 0 !important;
    
            & > * {
                margin: 0 !important;
                margin-left: 10px !important;
                margin-bottom: 10px !important;
            }
        }
        .customComponents__table {
            display: block !important;
            margin-top: 0;
        }
    }
    &__compoundPills {
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        flex-flow: wrap;
        flex-direction: row;
        font-family: "Roboto";
        
        & > p {
            color: #cacaca !important;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
        }
        & > span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            width: auto;
            height: 25px;
            font-size: 13px;
            border-radius: 5px;
            background-color: #434295;
            margin: 0px 5px;
            margin-bottom: 10px;

            &.error-pill {
                background-color: #c44c4c !important;
            }
        }
    }


    &__articlesPrint {
        width: 100%;
        height: max-content;
        display: flex;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;

        table, th, td {
            border: 0.1em solid black;
            border-collapse: collapse;
        }
        table {
            width: 100%;

            tr th, tr td span {
                padding: 2px 0px;
                margin: 0px 5px;
            }
            tr td span {
                display: inline-block;
            }
        }
    }
}

.route__adminPanel__articles__scaleleadOffers{
    display: flex;
    align-items: center;
    margin: auto;
    width: 700px;
    height: auto;
    background-color: #2b2c3e;
    padding: 20px;
    &__container{
        width: 100%;
        &>p{
            margin-bottom: 10px;
        }
        &__offers{
            &>div{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                margin: 10px;
                &>button{
                    background-color: #d82626;
                    border: none;
                    color: white;
                    border-radius: 3px;
                }
            }
        }
        &__buttons{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            margin-top: 20px;
            &>button{
                padding: 5px 20px;
                color: white;
                border: none;
                border-radius: 3px;
                &:first-child{
                    background-color: #434295;
                }
                &:last-child{
                    background-color: #d82626;
                }
            }
        }
    }
    
}