.component__singleClient{
    width: 95%;
    height: 95vh;
    background-color: #2b2c3e;
    margin: auto;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    &__close{
        position: absolute;
        right: 20px;
    }
    &__content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        height: 100%;
        &>p{
            &:first-child{
                font-size: 30px;
            }
        }
        &>img{
            width: 100px;
        }
        &__table{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2px;
            padding: 2px;
            background-color: #2b2c3e;
            &>p{
                background-color: #272737;
                padding: 5px 10px;
                width: 300px;
            }
        }
        &__buttons{
            display: flex;
            gap: 20px;
        }
    }
}