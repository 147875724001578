.route__kreirajNaljepnice__first{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:20px;
    gap: 20px;
    &>p{
        font-family: Roboto;
    }
    &>textarea{
        width: 100%;
        height: 400px;
        outline: none;
        padding: 20px;
    }
    &>button{
        padding: 5px 15px;
        cursor: pointer;
    }
}


// @media print {
//     #root {
//         padding: 0 !important;
//     }
//     .route__kreirajNaljepnice {
//         padding: 0 !important;
//     }
// }
// .route__kreirajNaljepnice {
//     padding: 5px;
//     display: grid;
//     grid-template-columns: 100%;
//     grid-template-rows: 100vh;
//     grid-auto-rows: 100vh;
//     width: 100%;
//     height: auto;
//     font-size: 10px;

//     @media print {
//         width: calc(100vw);
//         height: calc(100vh);
//     }
//     &__wrap {
//         display: grid;
//         grid-template-rows: repeat(6, calc(100% / 6));
//         grid-template-columns: repeat(2, 50%);
//         display: flex;
//         justify-content: flex-start;
//         align-items: flex-start;
//         page-break-after: always;
//         border-bottom: 1px solid black;
//         margin-bottom: 20px;

//         @media print {
//             border: none;
//             margin-bottom: 0;
//         }
//     }

//     &__naljepnica {
//         width: 100%;
//         height: 100%;
//         display: flex;
//         align-items: center;
//         flex-direction: column;
//         display: grid;
//         grid-template-rows: 100%;
//         grid-template-columns: 50% 50%;
//         column-gap: 30px;
//         & > p {
//             font-size: 30px;
//             & > p {
//                 font-size: 30px;
//             }
//         }

//         &__left {
//             padding-left: 5px;
//             &__dexpress {
//                 text-align: center;
//                 font-size: 30px;
//             }
//             &__barcode {
//                 text-align: center;
//                 font-size: 35px;
//                 margin-top: 10px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 width: 2550px;
//                 margin-left: (1600px);
//                 & canvas {
//                     width: 2550px;
//                     height: 350px;
//                     padding-left: 30px;
//                 }
//             }
//             &__barcodeBottom {
//                 text-align: center;
//                 font-weight: bold;
//                 margin-bottom: 5px;
//                 font-size: 40px;
//                 &* > {
//                     font-size: 50px;
//                 }
//             }
//             &__ref {
//                 display: flex;
//                 justify-content: space-between;
//                 font-size: 30px;
//             }
//             &__product {
//                 text-align: center;
//                 font-size: 50px;
//                 max-width: 100%;
//                 & > p {
//                     word-break: break-all;
//                     max-width: 100%;
//                 }
//             }
//             &__price {
//                 display: flex;
//                 justify-content: space-between;
//                 font-size: 30px;
//             }
//             &__date {
//                 text-align: center;
//                 font-size: 30px;
//             }
//         }
//         &__right {
//             p {
//                 word-break: break-all;
//             }
//         }
//     }
// }
// .route__kreirajNaljepnice__naljepnica__right__sender {
//     font-size: 40px;
//     & > p {
//         font-size: 30px;
//         width: 100%;
//     }
// }
// .route__kreirajNaljepnice__naljepnica__right__recipient {
//     font-size: 50px;
//     & > p {
//         font-size: 40px;
//         width: 100%;
//     }
// }

@media print {
    #root {
        padding: 0 !important;
    }
    .route__kreirajNaljepnice {
        padding: 0 !important;
    }
}
.route__kreirajNaljepnice {
    padding: 5px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100vh;
    grid-auto-rows: 100vh;
    width: 100%;
    height: auto;
    font-size: 10px;

    @media print {
        width: calc(100vw);
        height: calc(100vh);
    }
    &__wrap {
        display: grid;
        grid-template-rows: repeat(6, calc(100% / 6));
        grid-template-columns: repeat(2, 50%);
        justify-content: flex-start;
        align-items: flex-start;
        page-break-after: always;
        border-bottom: 1px solid black;
        margin-bottom: 20px;
        margin-top: 20px;

        @media print {
            border: none;
            margin-bottom: 0;
        }
    }
    &__wrapX {
        display: grid;
        grid-template-rows: repeat(6, calc(100% / 6));
        grid-template-columns: repeat(15, 6,6666666667%)  !important;
        justify-content: flex-start;
        align-items: flex-start;
        page-break-after: always;
        border-bottom: 1px solid black;
        margin-bottom: 20px;
        margin-top: 20px;

        @media print {
            border: none;
            margin-bottom: 0;
        }
    }

    &__naljepnica {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 50% 50%;
        column-gap: 30px;

        &__left {
            padding-left: 5px;
            &__dexpress {
                text-align: center;
                font-size: 7px;
            }
            &__barcode {
                text-align: center;
                font-size: 25px;
                margin-top: 10px;

                & canvas {
                    width: 350px;
                    padding-left: 30px;
                }
            }
            &__barcodeBottom {
                text-align: center;
                font-weight: bold;
                margin-bottom: 5px;
                font-size: 18px;
            }
            &__ref {
                display: flex;
                justify-content: space-between;
            }
            &__product {
                text-align: center;
                font-size: 8px;
            }
            &__price {
                display: flex;
                justify-content: space-between;
            }
            &__date {
                text-align: center;
            }
        }
        &__right {
            p {
                word-break: break-all;
                max-width: 140px;
            }
        }
    }
}
