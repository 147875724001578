.component__editLead__inputs{
    display: flex;
    justify-content: center;
    align-items: center;
    >input{
        width: 70%;
        height: 30px;
        padding: 0 10px;
        color: white;
        background-color: transparent;
        border: 1px solid gray;
        outline: none;
        margin: 4px;
        border-radius: 5px;
    }
    >label{
        width: 20%;
        text-align: left;
    }
}
.component__editLead__dropdown{
    width: 90%;
    margin: 10px auto;
    .component__customComponent__dropbox__placeholder{
        background-color: transparent;
        color: white;
        border: 1px solid gray;
        padding: 0 5px;
        position: relative;
    }
    .component__customComponent__dropbox {
        height: 30px;

    }
    
}