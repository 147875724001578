.component__agentCallIncoming{
    position: relative;
    width: 100%;
    overflow: auto;
    
    &__backButton{
        position: absolute;
        top: 20px;
        right: 20px;
        padding:5px 15px;
        border-radius: 5px;
        border: 1px solid red;
        cursor: pointer;
        font-size: 20px;
    }
}