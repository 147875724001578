@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  /* background-image: url('./backgrounderr.png'); */

}

#rootImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-size: cover;
  overflow: hidden;
}

.root__internalSpinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s ease;
}

.root__internalSpinner p {
  color: white;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gray;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: white grey;

}

.content-noLogin {
  position: relative;
}
