.component__createLead{
    all: initial;
    width: 682px;
    height: auto;
    background-color: #272737;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 30px;
    flex-direction: column;
    border-radius: 5px;
    opacity: 0;
    position: relative;
    
    & > .customComponents__input {
        width: 100% !important;

    }
    & > .component__customComponent__dropbox__text {
        font-size: 16px !important;
    }
    & > h1 {
        width: 100%;
        text-align: start !important;
        font-size: 18px !important;
        display: flex;
        align-items: center;

        & > img {
            margin-left: 15px;
        }
    }
    &__head {
        width: 100%;
        height: 20px;
        position: absolute;
        top: 24px;
        left: 0;

        & > img {
            position: absolute;
            top: 0px;
            right: 14px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
    >label{
        color: white;
        font-family: Roboto;

    }
    >input{
        width: 70%;
        height: 30px;
        background-color: transparent;
        border: 1px solid gray;
        margin: 10px;
        border-radius: 5px;
        outline: none;
        color: white;
        box-sizing: border-box;
        padding: 0 10px;
        text-align: center;
    }
    &__buttons{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
}