@keyframes hiddenModalRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
.component__modalController {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.50);
    transition: background-color 0.3s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &--hidden {
        background-color: transparent;
        animation-name: hiddenModalRotate;
        animation-timing-function: ease;
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        & > img {
            z-index: 2147000;
        }
    }
    & > img {
        width: 64px;
        height: 64px;
    }
}