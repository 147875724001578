$primary-color: var(--primary-color);
$text-main: var(--text-main);
$text-gray: var(--text-gray);
$text-blue: var(--text-blue);
$text-blue: var(--table-second);


.component__agentsDashboard {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        padding: 10px 40px;
        background-color: #3d3d55;
        & > p {
            font-family: Roboto;
            color: white;
        }
    }
    &__content {
        width: 100%;
        height: calc(100% - 70px);
        background-color: var(--primary-color);
        padding: 10px;
        display: grid;
        grid-template-columns: 270px 1fr 270px;
        gap: 0px;
        &__kpisLeft {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            padding: 10px;
            &__single {
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
                width: 100%;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 5px;
                gap: 10px;
                max-height: 24.5%;
                &__title {
                    font-family: Inter;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19.36px;
                    letter-spacing: 0%;
                    color: var(--text-gray);
                    text-align: center;
                    @media screen and (max-height: 1000px) {
                    font-size: 12px;
                    }
                }
                
                &__amount {
                    font-family: Inter;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 38.73px;
                    letter-spacing: 0%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    color: var(--text-main);
                    @media screen and (max-height: 1000px) {
                        font-size: 36px;
                        }
                    &>img{
                        height: 30px;
                        @media screen and (max-height: 1000px) {
                            height: 24px;
                            }
                    }
                }
                &__agent {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    font-family: Inter;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 21.78px;
                    letter-spacing: 0%;
                    color: var(--text-blue);

                    @media screen and (max-height: 1000px) {
                        font-size: 16px;
                        }
                    & > img { 
                        max-width: 20px;
                        max-height: 20px;
                        border-radius: 100%;
                        @media screen and (max-height: 1000px) {
                            height: 18px;
                            }
                    }
                }
                &__stats {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    & > div {
                        width: 118px;
                        height: auto;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        border: 1px solid #d4dfff;
                        background-color: #d4dfff;
                        padding: 5px 0;
                        & > p:first-child {
                            font-family: Inter;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 19.36px;
                            letter-spacing: 0%;
                            color: var(--text-blue);
                        }
                        & > p:last-child {
                            font-family: Inter;
                            font-weight: 600;
                            font-size: 22px;
                            line-height: 33.89px;
                            letter-spacing: 0%;
                            color: var(--text-blue);
                        }
                        &:last-child {
                            border: 1px solid #d6c3eeb5;
                            background-color: #d6c3eeb5;
                            color: #d6c3eeb5;
                        }
                    }
                }
            }
        }
        &__table {
            width: 100%;
            height: 100%;
            padding: 10px;
            &__kpi {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                
                &__single {
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
                    width: calc(50% - 20px);
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    border-radius: 5px;
                    gap: 10px;
                    & > p {
                        font-family: Inter;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24.2px;
                        letter-spacing: 0%;
                        color: var(--text-main);
                        width: 100%;
                    }
                    &__data {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        width: 100%;
                        gap: 20px;
                        & > div {
                            padding: 10px;
                            border-radius: 5px;
                            &:first-child {
                                border: 1px solid #29653a1f;
                                background-color: #29653a1f;
                                & > p:first-child {
                                    font-family: Inter;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19.36px;
                                    letter-spacing: 0%;
                                    color: #29653a;
                                }
                                & > p:last-child {
                                    font-family: Inter;
                                    font-weight: 600;
                                    font-size: 28px;
                                    line-height: 33.89px;
                                    letter-spacing: 0%;
                                    color: #29653a;
                                }
                            }
                            &:nth-child(2) {
                                border: 1px solid #d4dfff;
                                background-color: #d4dfff;
                                & > p:first-child {
                                    font-family: Inter;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19.36px;
                                    letter-spacing: 0%;
                                    color: var(--text-blue);
                                }
                                & > p:last-child {
                                    font-family: Inter;
                                    font-weight: 600;
                                    font-size: 28px;
                                    line-height: 33.89px;
                                    letter-spacing: 0%;
                                    color: var(--text-blue);
                                }
                            }
                            &:nth-child(3) {
                                border: 1px solid #3e048b1c;
                                background-color: #3e048b1c;
                                & > p:first-child {
                                    font-family: Inter;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19.36px;
                                    letter-spacing: 0%;
                                    color: #3e048b;
                                }
                                & > p:last-child {
                                    font-family: Inter;
                                    font-weight: 600;
                                    font-size: 28px;
                                    line-height: 33.89px;
                                    letter-spacing: 0%;
                                    color: #3e048b;
                                }
                            }
                        }
                    }
                }
            }
            &__content {
                width: 100%;
                max-height: calc(100% - 160px);
                height: auto;
                margin-top: 10px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
                border-radius: 5px;
                overflow: hidden;
                background-color: rgb(180, 180, 180);
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 57px auto;
                &__head {
                    display: grid;
                    grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
                    gap: 1px;
                    & > p {
                        background-color: var(--primary-color);
                        padding: 10px;
                        font-family: Inter;
                        color: var(--text-main);
                        & > div {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            & > p {
                                font-family: Inter;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 16.94px;
                                letter-spacing: 0%;
                                color: var(--text-gray);
                            }
                        }
                        // &:last-child > div {
                        //     grid-template-columns: 1fr 1fr;
                        // }
                    }
                }
                &__user {
                    display: grid;
                    grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
                    gap: 1px;
                    margin-top: 1px;
                    height: 48.2167px;
                    & > p {
                        background-color: var(--primary-color);
                        font-family: Inter;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19.36px;
                        letter-spacing: 0%;
                        color: #11002f;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0 10px;
                        gap: 10px;
                        & > img {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                        }
                    }
                    & > div {
                        background-color: rgb(180, 180, 180);
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        gap: 1px;
                        & > p {
                            background-color: var(--primary-color);
                            background-color: var(--primary-color);
                            font-family: Inter;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19.36px;
                            letter-spacing: 0%;
                            color: #11002f;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 0 10px;
                            gap: 10px;
                        }
                    }
                    &-dark p {
                        background-color: var(--table-second) !important;
                    }
                }
            }
        }
    }
}
