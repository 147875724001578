.component__chat__wrap__content__chat {
    &__message {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        max-width: 100%;
        left: -100%;

        &:hover &__userImage--hidden {
            opacity: 1;
        }
        &__userImage {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            margin-right: 12px;
            align-self: flex-start;

            &--hidden {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                opacity: 0;
                will-change: opacity;
                transition: opacity 0.3s ease;
            }
        }
        &__content {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            max-width: calc(100% - 46px);

            &__usr {
                font-size: 14px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;

                &__date {
                    color: #BEBEBE;   
                    font-weight: 400;
                    font-size: 12px;
                    margin-left: 8px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        &__text {
            &__click {
                margin-top: 10px;
                text-decoration: underline;
                color: #4f4f4f;
            }
            a {
                color: #ADABFF;
                cursor: pointer;
            }
        }
        &--primary {
            .component__chat__wrap__content__chat__message__text__click {
                color: #cac6c6;
            }
        }
        &--secondary &__text {
            a {
                color: #4C48F7;
            }
        }
    }
    &__message__text, &__message__lead {
        width: auto;
        max-width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 8px 10px;
        white-space: pre-wrap;
    }
    &__message__text {
        line-height: 20px;
        word-break: break-word;

        &__mention {
            font-weight: bold;
            text-decoration: underline;

            @keyframes mentionChatAnim {
                0% {
                    box-shadow: 0px 0px 10px 5px #95ab03a0;
                    background-color: #95ab03a0;
                }
                50% {
                    box-shadow: 0px 0px 0px 0px #6664e5a0;
                    background-color: transparent;
                }
            }

            &--active {
                box-shadow: 0px 0px 0px 0px #6664e5a0;
                color: #ADABFF;
                animation-name: mentionChatAnim;
                animation-duration: 3s;
                animation-timing-function: ease;
                animation-fill-mode: both;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
        }
    }
    &__message__lead {
        min-width: 300px;
        flex-direction: column;
        overflow: hidden;
        padding: 15px;

        @media screen and (max-width: 480px) {
            min-width: 100%;
            width: 100vh;
        }
        & > p {
            margin-top: 10px;
        }

        &__error {
            font-weight: bold;
        }
        &__data {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            cursor: pointer;

            & > small {
                font-size: 12px;
                filter: brightness(70%);
                margin-bottom: 5px;
            }
            & > span {
                font-size: 15px;
                font-weight: 700;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                & > img {
                    width: 32px;
                    height: 32px;
                    margin-right: 5px;
                    border-radius: 50%;
                }
            }
            &--status {
                margin-top: 20px !important;
                justify-content: center;
                align-items: center;

                & > div {
                    display: grid;
                    grid-template-rows: 2px;
                    column-gap: 5px;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    & > span {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    &__message--secondary &__message__lead__data--status {
        & > div > span {
            filter: invert(1) hue-rotate(180deg);
        }
    }
    &__message--secondary &__message__text {
        &__mention {
            &--active {
                color: #6664E5;
            }
        }
    }
    &__message__image {
        width: auto;
        max-width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 8px 10px;
        white-space: pre-wrap;
        cursor: pointer;

        & > p {
            font-weight: bold;
            margin-top: 10px;
            text-align: center;
        }
        & > img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 30vh;
        }
    }
    &__message__video {
        width: auto;
        max-width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 8px 10px;
        white-space: pre-wrap;
        cursor: pointer;

        & > p {
            font-weight: bold;
            margin-top: 10px;
            text-align: center;
        }
        & > video {
            width: auto;
            max-width: 100%;
            height: auto;
        }
    }
    &__message__file {
        width: auto;
        max-width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding: 8px 10px;
        white-space: pre-wrap;
        cursor: pointer;

        &__left {
            width: 48px;
            height: 48px;
            margin-right: 20px;

            & > img {
                width: 100%;
                height: 100%;
            }
        }
        &__right {
            width: 200px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__name {
                font-weight: bold;
                color: #ABA9F9;
                font-size: 20px;
                margin-bottom: 5px;
                word-break: break-word;
            }
            &__type {
                color: #BEBEBE;
            }
        }
    }
    &__message--secondary &__message__file {
        &__right {
            filter: invert(1) hue-rotate(180deg);
        }
    }
    &__message__video, &__message__image {
        &__fullscreen {
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            >img, >video{
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
        
            }
        }
    }
    &__message__note, &__message__task {
        flex-direction: column;

        & > h3 {
            margin-bottom: 10px;

            & > span {
                text-decoration: underline;
            }
        }
    }
    &__message__task {
        cursor: pointer;
    }
}