:root {
    --customComponents__slider__accent: gray;
}
.customComponents__slider {
    width: calc(100% - 20px);
    height: 30px;
    background-color: transparent;
    color: black;
    position: relative;

    &__thumb {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: white;
        border: 1px solid gray;
        border-radius: 50%;
        z-index: 3;
    }

    &__track, &__fill {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 4px;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 999px;
        border: 1px solid gray;
        z-index: 1;
    }
    &__fill {
        z-index: 2;
        width: 0;
        background-color: var(--customComponents__slider__accent);
    }
}
.customComponents__slider--dark.customComponents__slider {

    &.customComponents__slider {
        & .customComponents__slider__thumb {
            background-color: #717171 !important;
        }
    }
}