.route__adminPanel__leads {
    z-index: -2;
}
.customComponents__table__filter {
    .customComponents__table__head {
        height: 0;
    }
}
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: gray;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
}

.customComponents__kanban {
    width: auto;
    max-width: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: 270px;
    grid-auto-columns: 270px;
    grid-template-rows: auto;
    grid-auto-flow: column;
    column-gap: 5px;
    background-color: transparent;
    padding-top: 10px;
    height: calc(100% - 50px);
    margin: auto;
    z-index: -1;
    color: white;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    scrollbar-width: thin;
    scrollbar-color: gray black;
    z-index: 1;
    @media screen and (max-width: 800px) {
        height: 100vh !important;
        width: 100% !important;
    }
    &__statisticsButton {
        all: initial;
        position: relative;
        cursor: default;
        background-color: transparent;
        border: 1px solid #5a5b6a;
        border-radius: 4px;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        height: 30px;
        letter-spacing: 0.2px;
        line-height: 24px;
        outline: none;
        padding: 5px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__csv {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrap {
            width: 800px;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            padding: 10px;
            min-height: 200px;
            background-color: #2e2e41;
            color: white;
            position: relative;
            overflow-y: auto;

            & * {
                font-family: Roboto;
            }
            &__close {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                cursor: pointer;
                column-gap: 20px;
                margin-bottom: 10px;
            }
            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                z-index: 99;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &__count {
                margin-bottom: 10px;
            }
            &__title {
                margin-bottom: 20px;
            }
            &__columns {
                display: grid;
                grid-template-columns: repeat(auto-fit, 380px);
                grid-template-rows: auto;
                grid-auto-rows: auto;
                row-gap: 20px;
                column-gap: 20px;
            }
        }
    }
    &__leads {
        padding: 5px;
        max-height: 100%;
        overflow: auto;
        position: relative;
        padding-top: 0px;
        box-sizing: border-box;
        > p {
            text-align: center;
            font-size: 18px;
            padding: 5px 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            position: sticky;
            width: 100%;
            top: 0;
            color: white;
            background-color: #2b2c3e;
            font-family: Roboto;

            display: flex;
            justify-content: center;
            align-items: center;

            & .customComponents__spinner {
                margin: 0px 5px;
            }
        }
    }
}

.customComponents__kanban__customDate {
    all: initial;
    position: relative;
    cursor: default;

    background-color: transparent;
    border: 1px solid #5a5b6a;
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 30px;
    letter-spacing: 0.2px;
    line-height: 24px;
    outline: none;
    padding: 5px 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    &__picker {
        position: absolute;
        top: 110%;
        right: 0%;
        cursor: default;

        z-index: 99;
        overflow: hidden;
        background-color: #272737;
        box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.3);

        transition:
            max-height 0.3s ease,
            box-shadow 0.3s ease;

        &__buttons {
            width: 100%;
            height: auto;
            border-bottom: 1px solid gray;
            margin-bottom: 10px;
            padding: 10px 5px;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            & > * {
                padding: 0 10px;
                width: max-content;
                height: 30px;

                transition: border 0.3s ease;
            }
        }
    }
    &__selected {
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
            width: 14px;
            height: 14px;

            margin-left: 10px;
            cursor: pointer;
        }
    }
}

.component__kanban__context {
    position: absolute;
    background-color: rgb(46, 46, 65);
    border: 0px solid rgb(153, 159, 174);

    padding: 0px 5px;
    width: auto;
    max-height: 0px;
    overflow: hidden;
    z-index: 99;
    transition:
        max-height 0.3s ease,
        padding 0.3s ease,
        border 0.3s ease;

    &--active {
        max-height: 300px;
        padding: 5px;
        border: 1px solid rgb(153, 159, 174);
    }

    & > p {
        color: white;
        font-size: 15px;
        font-family: Roboto;
        margin-bottom: 10px;
        padding: 10px;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.kanbanLead {
    width: 100%;
    background-color: #272737;
    margin-top: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    max-height: 0;

    > p {
        font-size: 14px;
        font-family: Roboto;
        color: white;
        font-family: Roboto;
        font-weight: 700;
        > img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    > small {
        font-size: 12px;
        color: gray;
        font-family: Roboto;
        line-height: 0px;
    }

    &__dealShipment {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        margin-top: 20px;

        & > div {
            width: 100%;
            height: 2px;
            margin-top: 10px;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
            column-gap: 5px;

            & > span {
                display: block;
                width: 100%;
                height: 100%;
                background-color: #534c4c;
            }
        }
    }

    scrollbar-width: thin !important;
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: gray !important;
        border-radius: 10px;
    }
}
.customComponents__filterPanel {
    width: 100%;
    &__button {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > p {
            max-width: 100% !important;
            color: white;
            font-family: Montserrat;
            margin-right: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            padding: 10px 5px;
            border-radius: 7px !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 40px;
            margin-left: 100px;
            cursor: default;
            transition: background-color 0.3s ease;
            background-color: #272737;
            font-size: 18px;
            cursor: pointer;
        }
    }
}
.component__kanban__filters {
    @media screen and (max-width: 800px) {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .customComponents__filterPanel {
            display: flex;
            align-items: center !important;
            width: 100% !important;
            flex-wrap: wrap;
        }
        .customComponents__filterPanel__filterList {
            margin: 0 20%;
            width: 60% !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .customComponents__filterPanel__addFilterWrap--active {
            max-height: 300px !important;
            padding: 10px;
            > * {
                width: 100%;
                margin: 10px 0 0 0 !important;
            }
        }
    }
}
