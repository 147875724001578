.component__agentCall {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: auto;
    transition: opacity 0.3s ease !important;
    opacity: 0;

    &__noGuarantee {
        font-family: "Roboto";
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        &__wrap {
            width: 600px;
            height: auto;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-self: center;
            flex-direction: column;
            background-color: #2b2c3e;

            & > h2 {
                margin-bottom: 10px;
            }
            &__buttons {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;

                & > * {
                    margin: 0px 10px;
                }
            }
        }
    }
    &__call {
        width: 40%;
        height: 100%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        overflow: hidden;

        &__back {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.5);
        }
        &__helper {
            width: 100%;
            height: calc(100% - 100px);
            position: absolute;
            top: 0;
            z-index: 10;
        }
        &__front {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #272737;
            border-top: 2px solid #3D3D55;

            &__leadImage {
                width: 300px;
                height: 300px;
                border-radius: 50%;
                margin-right: 0 !important;
                margin-bottom: 80px;
            }
            &__leadName {
                color: white;
                font-family: Roboto;
                font-weight: 700;
                font-size: 20px !important;
                line-height: 33px;
                text-align: center;
                text-transform: capitalize;
            }
            &__leadPhone {
                color: white;
                font-family: Roboto;
                font-weight: 500;
                font-size: 20px !important;
                line-height: 33px;
                text-align: center;
                text-transform: capitalize;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 50%;

                &__status {
                    margin-top: 20px;
                }
            }
            &__buttons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 50%;

                &__green {
                    width: 60px;
                    height: 60px;
                    border: none;
                    border-radius: 53px;
                    color: white;
                    font-family: Roboto;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 500;
                    margin: 0 19px;
                    cursor: pointer;

                    &--red {
                        background-color: #ff5752;
                    }
                }
                &__red {
                    width: 200px;
                    height: 60px;
                    background-color: #3d3d55;
                    border: none;
                    border-radius: 53px;
                    color: white;
                    font-family: Roboto;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 500;
                    margin: 0 19px;
                    cursor: pointer;
                }
            }
        }
    }
    &__info {
        width: 60%;
        height: 100%;
        background-color: #3d3d55;
        box-sizing: border-box;
        padding: 30px 46px 66px 66px;
        overflow-y: scroll;
        position: relative;
        &__head {
            font-family: Roboto;
            font-weight: 600;
            color: white;
            font-size: 32px;
            line-height: 44px;
            margin-bottom: 32px;
        }
        &__tabs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &__single {
                padding: 20px;
                margin-right: 2px;
                background-color: white;
                font-family: Roboto;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                color: #000000;
                background-color: #272737;
                color: white;
                cursor: pointer;
                &--active {
                    border-bottom: 4px solid #6664e5;
                }
            }
        }
        &__tabsContent {
            min-height: 300px;
            &__single {
                height: auto;
                > p {
                    color: white;
                }
                &__agentInfoHeading {
                    padding-top: 20px;
                    color: white;
                }
                &--agent {
                    font-family: "Roboto";
                }
                &__statusCodes {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: start;
                    margin-bottom: 20px;
                    &__text {
                        padding: 11px;
                        border: 1px solid white;
                        border-radius: 2px;
                        min-width: 110px;
                        text-align: center;
                        margin-right: 30px;
                        margin-top: 30px;
                        cursor: pointer;
                        font-family: Roboto;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 28px;
                        color: white;
                        transition: all 0.3s ease;
                        &--active {
                            color: white;
                        }
                        &--active#Obrada {
                            border-color: #d1c411;
                            box-shadow: 0px 0px 10px 0px #d1c411 inset;
                        }
                        &--active#Pozvatiponovo {
                            border-color: #00dbc9;
                            box-shadow: 0px 0px 10px 0px #00dbc9 inset;
                        }
                        &--active#Loslead {
                            border-color: #ff5752;
                            box-shadow: 0px 0px 10px 0px #ff5752 inset;
                        }
                        &--active#Odbijenlead {
                            border-color: #ef31ef;
                            box-shadow: 0px 0px 10px 0px #ef31ef inset;
                        }
                        &--active#Dobarlead {
                            border-color: rgb(105, 170, 249);
                            box-shadow: 0px 0px 10px 0px rgb(105, 170, 249) inset;
                        }
                    }
                }
                &__dropdownContainer {
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    &>.component__customComponent__dropbox {
                        width: 45%;
                    }
                    &__inputList {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                        flex-wrap: wrap;
                        width: 100%;

                        &__input {
                            padding: 5px 10px;
                            color: #fff;
                            border: 1px solid rgb(102, 100, 229);
                            background-color: rgb(46, 46, 65);
                            border-radius: 5px;

                            transition: background-color 0.3s ease, color 0.3s ease;
                            cursor: pointer;

                            &--active {
                                background-color: rgb(102, 100, 229);
                            }
                        }
                    }
                    &__inputContainer{
                        width: 45%;

                        &--recurring {
                            display: grid;
                            grid-template-rows: auto auto;
                            grid-template-columns: auto 1fr;

                            & > label {
                                grid-column: 1 / span all;
                            }
                        }
                        &--sum {
                            flex-basis: 100%;
                            margin-top: 30px;
                            color: white;
                            
                            & > p {
                                margin: 5px 0;
                            }
                        }
                        &__spacer {
                            width: 100%;
                            height: 1px;
                            background-color: gray;
                            margin: 8px 0;
                        }
                        &>.component__customComponent__dropbox {
                            box-shadow: none !important;
                            border: none !important;
                            border-bottom: 1px solid white !important;
                            border-radius: 0 !important;
                        }
                        &>.components__customComponents__checkbox {
                            margin-top: 10px;
                        }
                    }
                    .component__customComponent__dropbox__placeholder {
                        background-color: #f1f1f1;
                    }
                    &__guaranteePrint {
                        margin-top: 20px;
                    }
                    &__articles {
                        width: 100%;
                        border-left: 1px solid gray;
                        margin-left: 10px;
                        
                        &__singleArticle {
                            margin: 5px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-direction: row-reverse;
                            >.customComponents__buttonSmall {
                                width: 100px !important; 
                            }
                            >.component__customComponent__dropbox {
                                width: calc(100% - 115px) !important;
                                box-shadow:  none !important;
                                border: none !important;
                                border-radius: 0px !important;
                                position: relative;

                                .component__customComponent__dropbox__text {

                                    &::after {
                                        content: '';
                                        position: absolute;
                                        bottom: 0;
                                        left: 10px;
                                        width: calc(100% - 20px);
                                        height: 1px;
                                        background-color: white;
                                    }
                                }
                            }

                            &__selected {
                                margin-right: auto;
                                margin-left: 10px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                color: white;

                                & > * {
                                    margin: 0px 5px;
                                }

                                & .customComponents__input {
                                    width: 100px !important;
                                    max-width: 100px !important;
                                    min-width: 100px !important;

                                    & > input {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                    &__label {
                        font-family: Roboto;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 18px;
                        color: white;
                        width: 100%;
                        display: block;
                        margin-top: 15px;
                        margin-bottom: 5px;
                        width: 100%;
                    }
                    &__input {
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        outline: none;
                        border-bottom: 1px solid #969698;
                        box-sizing: border-box;
                        padding: 10px;
                        color: white;
                        font-family: Roboto;
                    }
                }
                &__dropdownContainerIllness {
                    width: 90%;
                    margin-top: 20px;
                    padding-top: 20px;
                    color: white;
                    border-top: 1px solid gray;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 20px;
                    flex-wrap: wrap;

                    & > h3 {
                        flex-basis: 100%;
                    }
                }
                &__callAgainModal {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    background-color: #000000a1;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 10;

                    &__modal {
                        width: 400px;
                        height: 600px;
                        background-color: #f1f1f1;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        & > div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            width: 100%;
                            height: auto;

                            & > input {
                                width: 50%;
                                height: 30px;
                                margin-bottom: 10px;
                            }
                            & > label {
                                font-family: Roboto;
                            }
                        }
                        &__green {
                            width: 50%;
                            background-color: #6664e5;
                            border: none;
                            cursor: pointer;
                            height: 30px;
                            color: white;
                            font-family: Roboto;
                            font-size: 20px;
                            font-weight: 700;
                            border-radius: 40px;
                            margin-bottom: 10px;
                        }
                        &__red {
                            width: 50%;
                            background-color: #3d3d55;
                            border: none;
                            cursor: pointer;
                            height: 30px;
                            color: white;
                            font-family: Roboto;
                            font-size: 20px;
                            font-weight: 700;
                            border-radius: 40px;
                            margin-bottom: 10px;

                            &--active {
                                background-color: #6664e5;
                            }
                        }
                        &__customButtons {
                            width: 100%;
                            height: 30px;
                            display: grid;
                            grid-template-rows: 1fr;
                            grid-template-columns: repeat(3, 1fr);
                            justify-content: center;
                            align-items: center;
                            column-gap: 20px;
                            margin-bottom: 20px;
                            padding: 20px;

                            & > button {
                                width: 100% !important;
                                height: 30px !important;
                                font-size: 14px;
                                transition: background-color 0.3s ease;
                            }
                        }
                    }
                }
                &__callAgain {
                    font-family: Roboto;
                    font-weight: 500;
                    margin-top: 10px;
                    > span {
                        color: red;
                    }
                }
                &__saveButton {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    &__btn {
                        width: 200px;
                        background-color: #6664e5;
                        border: none;
                        cursor: pointer;
                        color: white;
                        font-family: Roboto;
                        font-size: 20px;
                        font-weight: 700;
                        border-radius: 40px;
                        margin-bottom: 10px;
                        height: 40px;
                        margin: 10px;
                    }
                    &__btnRed {
                        width: 200px;
                        height: 40px;
                        background-color: #373741;
                        border: none;
                        cursor: pointer;
                        color: white;
                        font-family: Roboto;
                        font-size: 20px;
                        font-weight: 700;
                        border-radius: 40px;
                        margin-bottom: 10px;
                        margin: 10px;
                    }
                }
                &__history {
                    display: flex;
                    flex-direction: column;
                    column-gap: 20px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid #aca9a9;
                    box-sizing: border-box;
                    padding: 20px;
                    border-radius: 3px;
                    margin-top: 5px;
                    position: relative;
                    &__user {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row-reverse;
                        width: auto;
                        height: 100%;

                        &__info {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: flex-start;
                            flex-direction: column;
                            height: 64px;

                            & > p {
                                font-family: Roboto;
                                font-size: 18px;
                                font-weight: 600;
                                flex-grow: 0;
                                flex-shrink: 0;
                                color: white;

                                & > span:first-child {
                                    display: block;
                                    font-weight: 400;
                                    color: rgb(182, 182, 182);
                                    margin-bottom: 5px;
                                    text-align: start;
                                }
                            }
                        }
                        & > p {
                            font-family: Roboto;
                            font-size: 18px;
                            font-weight: 600;
                            flex-grow: 0;
                            flex-shrink: 0;
                            color: white;
                            & > span:first-child {
                                display: block;
                                font-weight: 400;
                                color: rgb(182, 182, 182);
                                margin-bottom: 5px;
                            }
                        }
                        & > img {
                            width: 64px;
                            height: 64px;
                            border-radius: 50%;
                            margin-right: 20px !important;
                        }
                    }
                    &__content {
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 10px 10px;
                        padding-left: 85px;

                        & > p {
                            font-family: "Roboto";
                            font-size: 15px;
                            word-break: break-word;
                            width: 100%;
                            color: white;
                            user-select: none;
                        }
                        &__date {
                            font-family: "Roboto";
                            font-size: 14px;
                            color: #999fae;
                        }
                        & > audio {
                            width: 100%;
                        }
                    }
                }
                &__addComment {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0px;

                    & > textarea {
                        border: 1px solid gray;
                        margin-bottom: 10px;
                        resize: none;
                        padding: 5px;
                        height: 100px;
                        background-color: transparent;
                        color: white;
                    }
                    & > button {
                        width: 100%;
                        height: 30px;
                        background-color: #6664e5;
                        border: none;
                        border-radius: 5px;
                        color: white;
                        font-family: Roboto;
                        font-size: 20px;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
                &__reference {
                    padding: 20px 30px;
                    background-color: white;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    font-family: Roboto;
                    font-size: 20px;
                }
            }
        }
    }
}

.component__agentHelper {
    width: 100%;
    height: 100%;
    background-color: #272737;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .customComponents__buttonSmall {
        border-radius: 30px;
    }
    &__steps {
        display: flex;
        justify-content: space-around;
        align-items: center;
        > p {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            > span {
                border-radius: 50%;
                padding: 5px 10px;
            }
        }
        > img {
            margin: 0;
        }
    }
    > h2 {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #999fae;
    }
    > div {
        .component__customComponent__dropbox {
            border: none;
            border-bottom: 1px solid white;
            box-shadow: none;
            border-radius: 0;
            width: 300px;
            margin: auto;
        }
        > h2 {
            font-family: Roboto;
            font-size: 32px;
            font-weight: 700;
            line-height: 46px;
            letter-spacing: 0em;
            text-align: center;
        }
        > p {
            text-align: center;
            font-family: Roboto;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .customComponents__buttonSmall {
            width: 150px;
            margin: 0 10px !important;
        }
    }
    &__questions {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        padding: 10px;
        > p {
            width: 50%;
            font-family: Roboto;
        }
        .component__customComponent__dropbox {
            width: 40%;
        }
    }
}
.sendMessage {
    width: 500px;
    height: 500px;
    background-color: #272737;
    padding: 30px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 5px;
    position: relative;
    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .customComponents__input__textbox {
        margin-bottom: 30px;
        color: white;
        width: 100%;
    }
    & > .component__customComponent__dropbox,
    & > .customComponents__input {
        width: 100%;
        color: white;
    }
    & > .customComponents__buttonSmall:last-child {
        width: 50%;
    }
    & > p {
        text-align: center;
    }
}

.component__agentCall__checkArticleInfo {
    width: 100%;
    height: auto;
    margin-top: 10px;

    color: white;
}

.component__agentCall__checkProductAndPrice{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container{
        padding: 20px;
        background-color: #272737;
        &>p{
            font-family: Roboto;
            color: white;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px;
        }
        &>.component__customComponent__dropbox {
            grid-template-columns: 1fr 20px !important;
        }
        &>textarea{
            box-shadow: rgb(102, 100, 229) 0px 0px 2px, rgb(102, 100, 229) 0px 0px 4px;
            border-bottom: 1px solid gray;
            padding: 10px;
            margin-bottom: 20px;
            gap: 20px;
            min-width: 136px;
            border: 1px solid #5A5B6A;
            box-sizing: border-box;
            border-radius: 4px;
            color: #19191D;
            display: block;
            position: relative;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:  12px;
            width: 100%;
            height: 100px;
            background-color: transparent;
            color: white;
            font-family: Roboto;
            border: none;
            outline: none;
        }
        &>div{
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid gray;
            padding: 10px;
            margin-bottom: 20px;
            gap: 20px;
            &>p{
                font-family: Roboto;
                color: white;
                font-size: 18px;
            }
            &>button{
                min-height: 40px;
                cursor: pointer;
                height: 100%;
                color: white;
                border: none;
                border-radius: 3px;
                &:first-child{
                    background-color: #6664e5;

                }
                &:last-child{
                    background-color: #3D3D55;
                }
            }
        }
    }
}



