.route__modals__viewLeads {
    width: 100%;
    height: 100%;

    overflow: auto;
    color: white;
    background-color: #2e2e41;

    &__modal {
        padding: 20px;
        background-color: #2e2e41;
        box-shadow: 0 0 14px rgba(0,0,0,.35);
    }
}