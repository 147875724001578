.route__adminPanel__images {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px;
    cursor: default;

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & .customComponents__buttonSmall {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }

    &__data {
        padding: 20px;
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, 300px);
        grid-template-rows: max-content;
        grid-auto-rows: max-content;
        justify-content: center;
        align-items: flex-start;
        column-gap: 20px;
        row-gap: 20px;

        & > div {
            width: 100%;
            height: 200px;
            background-size: 100% 100%;
        }
        &__clickable {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__addImage {
        background-color: #2b2c3e;
        padding: 20px 40px;
        width: 400px;
        margin: 0 auto;
        cursor: default;
        position: relative;

        & .customComponents__buttonSmall {
            margin: 20px;
        }
        & > div {
            margin: 30px 0px;
        }
        &__infoP {
            color: #d82626;
        }
        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #2b2c3e;
            margin: 0 !important;
            z-index: 999;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            & > p {
                margin-bottom: 30px;
            }
        }
        &__placeholder {
            width: 100%;
            min-height: 200px;
            height: auto;
            position: relative;

            & > img {
                width: 100%;
                min-height: 200px;
                height: auto;
                border: none;
                user-select: none;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
            & > p {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #00000070;
                opacity: 0;
                transition: opacity 0.3s ease;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    &__removeImage {
        background-color: #2b2c3e;
        color: white;
        font-family: "Roboto";
        padding: 20px 40px;
        width: 400px;
        margin: 0 auto;
        cursor: default;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & .customComponents__buttonSmall {
            margin: 20px;
        }
        & > div {
            margin: 30px 0px;
        }
        & > img {
            width: 100%;
            height: auto;
        }
        & > p {
            text-align: center;
            margin-bottom: 20px;
        }
        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #2b2c3e;
            margin: 0 !important;
            z-index: 999;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            & > p {
                margin-bottom: 30px;
            }
        }
    }
}