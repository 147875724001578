.modal__yesNo {
    width: auto;
    max-width: 500px;
    height: auto;
    background-color: white;
    box-shadow: 0px 0px 15px rgba(67, 76, 109, 0.14);
    border-radius: 5px;
    padding: 30px;

    position: absolute;
    top: 129px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--dark {
        filter: invert(1) hue-rotate(180deg);

        & img {
            filter: invert(1) hue-rotate(180deg);
        }
    }
    &__heading {
        color: #19191d;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
    }
    &__text {
        color: #19191d;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 30px;
    }
    &__wrap {
        width: 100%;
        height: 40px;
        display: grid;
        grid-template-rows: 40px;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        align-items: center;

        &__button {
            width: 100%;
            height: 100%;
            box-shadow: 0px 0px 4px rgba(67, 76, 109, 0.2);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            user-select: none;
            cursor: pointer;
            border-radius: 33px;

            &--gray {
                background-color: #f4f4f4;
                color: #686868;
            }
            &--green {
                background-color: #39d84b;
                color: white;
            }
        }
    }
}

.modal__sipCall {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    color: white;
    background-color: #272737;
    box-shadow: 0px 0px 15px rgba(67, 76, 109, 0.14);
    border-radius: 5px;
    // padding: 60px 0 0 0;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    &__call {
        max-width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        user-select: none;
    }
    &__customerData {
        width: 100%;
        height: 100%;
        overflow: auto;
        &__first {
            padding-top: 50px;
            & > p {
                font-family: Roboto;
                font-size: 18px;
            }
            &__shop {
                color: #6664e5;
                font-size: 24px !important;
                text-align: center;
            }
            & > div {
                background-color: #19191d;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 2px;
                margin-bottom: 10px;
                & > p {
                    font-family: Roboto;
                    background-color: #2e2e41;
                    padding: 5px 10px;
                    cursor: pointer;
                }
            }
        }
        &__selected {
            & > p {
                font-family: Roboto;
                cursor: pointer;
                padding: 5px 10px;
            }
            &__deal {
                & > * {
                    color: white;
                    font-family: Roboto;
                    margin-bottom: 20px;
                }
                &__buttons {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 50px;
                    & > p {
                        cursor: pointer;
                        font-size: 20px;
                        padding: 10px 20px;
                        border-radius: 3px;
                        background-color: #6664e5;
                    }
                }
            }
        }
    }

    &__minimize {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 30px;
        height: 30px;
        padding: 5px;
        transition: background-color 0.3s ease;
        cursor: pointer;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% - 10px);
            height: 3px;
            background-color: white;
            border-radius: 2px;
            transform: translate(-50%, -50%);
        }
        &:hover {
            background-color: #4e4e6c;
        }
    }
    &__img {
        width: 290px;
        height: 290px;
        border-radius: 50%;
        border: 1px solid rgba(49, 144, 255, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
            width: 223px;
            height: 223px;
        }
    }
    &__caller {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__callerInfo {
        margin-top: 30px;
        font-family: "Poppins";
        font-style: normal;
        line-height: 140%;
        text-align: center;

        &__callerName {
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 140%;
        }
        &__callerPhone {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            margin-top: 18px;
            user-select: text;
        }
    }
    &__sipStatus {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        & > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
        }
    }
    &__sipButtons,
    &__sipActions {
        margin-top: 39px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
            margin: 0px 17px;
            padding: 10px 30px;
            background: #3d3d55;
            border-radius: 53px;
            cursor: pointer;

            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            text-transform: uppercase;
        }
    }
    &__sipActions {
        &__red {
            background-color: #e94242 !important;
        }
        &__green {
            background-color: #6664e5 !important;
        }
        &__yellow {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;

            &.cloned-element {
                z-index: 9999 !important;
            }
        }
    }
    &__sendNotification {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrap {
            width: 500px;
            height: auto;
            padding: 20px;
            background-color: #2e2e41;

            & > textarea {
                width: 100%;
                height: 300px;
                background-color: #252534;
                color: white;
                border: 1px solid gray;
                margin-top: 10px;
                outline: none;
                padding: 5px;
                resize: none;
                align-self: center;
            }
            &__btns {
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;

                & > * {
                    margin-left: 10px;
                }
            }
        }
    }
}
